import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-check',
    templateUrl: './icon-check.component.html',
    styleUrls: ['./icon-check.component.scss']
})
export class IconCheckComponent {
    constructor() {
    }
}
