<div class="row hd1">
  <div class="d-flex align-items-start">
    <div class="col-md-3" style="margin-right: 20px">
      <nb-card style="margin-top: 10px">
        <nb-card-header>
          <h4 style="color: red !important">
            <strong>ĐƠN VỊ TRẢ LỜI</strong>
          </h4>
        </nb-card-header>
        <nb-card-body>
          <p>
            <a  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <h6><i class="fa-solid fa-caret-down"></i>&nbsp; Sở, Ban ngành</h6>
            </a>
          </p>
          <div class="collapse" id="collapseExample">

              <p *ngFor="let item of ListSoBanNganh">
                <a
                  [routerLink]="['/web-home/hoidapchitiet', item.unitcode, 0]"
                  style="margin-top: 20px; cursor: pointer; color:#828081"
                >
                  {{ getTenUnit(item.unitcode) }}
                </a>
              </p>
          </div>
          <p>
            <a  data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
              <h6><i class="fa-solid fa-caret-down"></i>&nbsp; Thành phố, huyện, thị xã</h6>
            </a>
          </p>
          <div class="collapse" id="collapseExample1">

              <p *ngFor="let item of ListHuyenthanhthi">
                <a
                  [routerLink]="['/web-home/hoidapchitiet', item.unitcode, 0]"
                  style="margin-top: 20px; cursor: pointer; color:#828081"
                >
                  {{ getTenUnit(item.unitcode) }}
                </a>
              </p>
          </div>
          <p>
            <a  data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
              <h6><i class="fa-solid fa-caret-down"></i>&nbsp; Đơn vị khác</h6>
            </a>
          </p>
          <div class="collapse" id="collapseExample2">

              <p *ngFor="let item of ListDonvikhac">
                <a
                  [routerLink]="['/web-home/hoidapchitiet', item.unitcode, 0]"
                  style="margin-top: 20px; cursor: pointer; color:#828081"
                >
                  {{ getTenUnit(item.unitcode) }}
                </a>
              </p>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-md-9" style="padding-right: 20px">
      <div class="tab-content" id="v-pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="v-pills-home"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <h4 style="color: royalblue; margin-top: 16px">GỬI CÂU HỎI</h4>
          <hr style="border: 3px solid purple" />
          <form [formGroup]="deo">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2" style="margin-top: 8px">
                  <p class="title required">Họ và tên</p>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <textarea
                      rows="1"
                      type="text"
                      class="form-control"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="target.tenNguoiGui"
                      name="tenNguoiGui"
                      maxlength="500"
                      #tenNguoiGui="ngModel"
                      required
                      placeholder="Nhập tên người gửi"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2" style="margin-top: 8px">
                  <p class="title ">Địa chỉ</p>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <textarea
                      rows="1"
                      type="text"
                      class="form-control"
                      [(ngModel)]="target.diachi"
                      name="diachi"
                      maxlength="500"
                      #diachi="ngModel"
                      required
                      placeholder="Nhập địa chỉ"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2" style="margin-top: 8px">
                  <p class="title">Email</p>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <textarea
                      rows="1"
                      type="text"
                      class="form-control"
                      [(ngModel)]="target.email"
                      name="email"
                      maxlength="500"
                      #email="ngModel"
                      required
                      placeholder="Nhập email"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2" style="margin-top: 8px">
                  <p class="title ">Điện thoại</p>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <textarea
                      rows="1"
                      type="text"
                      class="form-control"
                      [(ngModel)]="target.dienThoai"
                      name="dienThoai"
                      maxlength="500"
                      #dienThoai="ngModel"
                      required
                      placeholder="Nhập số điện thoại"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2" style="margin-top: 10px">
                  <p class="title required">Tiêu đề</p>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <textarea
                      rows="1"
                      type="text"
                      class="form-control"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="target.tieuDe"
                      name="tieuDe"
                      maxlength="500"
                      #tieuDe="ngModel"
                      required
                      placeholder="Nhập tiêu đề"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2" style="margin-top: 20px">
                  <p class="title required">Nội dung</p>
                </div>
                <div class="col-md-10">
                  <div class="control-section">
                    <ejs-richtexteditor
                      id="defaultRTE"
                      [toolbarSettings]="tools"
                      formControlName="noidung"
                      [insertImageSettings]="insertImageSettings"
                    >
                      <ng-template> </ng-template>
                    </ejs-richtexteditor>
                  </div>
                  <div id="rteContent" style="display: none"></div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2" style="margin-top: 5px">
                  <p class="title">File đính kèm</p>
                </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <div class="input-group">
                      <ngx-btn-file
                        (change)="chooseFile($event.target.files)"
                        [multiple]="true"
                        name="file"
                        [accept]="
                          'image/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .rtf, .txt, .zip, .pdf, .rar, .tar, .gif'
                        "
                      >
                      </ngx-btn-file>
                      &nbsp;&nbsp;
                      <ul
                        *ngIf="selectedFiles && selectedFiles.length > 0"
                        style="list-style: none"
                      >
                        <li class="file-x" *ngFor="let file of selectedFiles">
                          <ngx-icon-delete
                            (click)="deleteFile(file)"
                          ></ngx-icon-delete
                          >&nbsp; {{ file.fileName }}&nbsp;
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 mb-2">
                <re-captcha
                  id="recaptcha"
                  name="recaptcha"
                  #recaptcha="ngModel"
                  [(ngModel)]="token"
                  (resolved)="resolved($event)"
                  required
                  [class.is-invalid]="
                    recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)
                  "
                ></re-captcha>
                <div
                  *ngIf="
                    recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)
                  "
                  class="invalid-feedback"
                >
                  <div *ngIf="recaptcha.errors?.['required']">
                    This field is required.
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div style="margin: 20px 0px 0px 0px">
        <button
          (click)="create()"
          [disabled]="checkcaptcha"
          style="height: 40px"
          type="button"
          class="btn btn-primary justify-content-center"
        >
          <h6 class="tb">Gửi câu hỏi</h6>
        </button>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="col" style="width: 500px">
              Câu hỏi đã trả lời
            </th>
            <th scope="col" class="col" style="width: 200px">Người hỏi</th>
            <th scope="col" class="col" style="width: 200px">Ngày hỏi</th>
            <th scope="col" class="col" style="width: 200px">
              Trạng thái xử lý
            </th>
            <th scope="col" class="col" style="width: 200px">Ngày phản hồi</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of LstbaivietCT.data.data">
          <tr>
            <td>
              <a class="hv" (click)="clickloadtintuc(item.id, item.loaihoidap)"
                >{{ item.tieuDe }}
              </a>
            </td>
            <td>
              <a
                class="hv"
                (click)="clickloadtintuc(item.id, item.loaihoidap)"
                >{{ item.tenNguoiGui }}</a
              >
            </td>
            <td>
              <a
                class="hv"
                (click)="clickloadtintuc(item.id, item.loaihoidap)"
                >{{ item.time | date : "dd/MM/yyyy" }}</a
              >
            </td>
            <td>
              <a
                class="hv"
                (click)="clickloadtintuc(item.id, item.loaihoidap)"
                >{{ getTenTrangthai(item.matrangthai) }}</a
              >
            </td>
            <td>
              <a
                class="hv"
                (click)="clickloadtintuc(item.id, item.loaihoidap)"
                >{{ item.timePhanHoi | date : "dd/MM/yyyy" }}</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div style="justify-content: center; display: flex">
        <ngx-pagination
          (pageChange)="
            searchModel.currentPage = $event; loadData_baitin_khac()
          "
          (pageSize)="searchModel.pageSize = $event; loadData_baitin_khac()"
          [currentPage]="searchModel.currentPage"
          [totalPages]="LstbaivietCT.data.totalPage"
        >
        </ngx-pagination>
      </div>
    </div>
  </div>
</div>
