<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
            <h2 style="text-align: center; color: royalblue">Danh sách hỏi đáp</h2>
        </div>
    </div>
  </nb-card-header>
<nb-card-body>
<div class="row hd1">
    <div class="col-md-12" style="margin-top: 25px;">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">STT</th>
            <th scope="col">Câu hỏi đã trả lời</th>
            <th scope="col">Người hỏi</th>
            <th scope="col">Ngày trả lời</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of LstbaivietCT.data.data">
          <tr>
            <th scope="row"></th>
            <td><a
              class="hv"
              (click)="clickloadtintuc(item.id,item.loaihoidap)"
              >{{ item.tieuDe }}
            </a></td>
            <td><a
              class="hv"
              (click)="clickloadtintuc(item.id,item.loaihoidap)"
              >{{item.tenNguoiGui}}</a></td>
            <td>
              <a
              class="hv"
              (click)="clickloadtintuc(item.id,item.loaihoidap)"
              >{{item.timePhanHoi | date: "dd/MM/yyyy"}}</a></td>
          </tr>


        </tbody>
      </table>
      <div style="justify-content:center; display: flex;">
        <ngx-pagination
          (pageChange)="searchModel.currentPage = $event;  loadData_baitin_khac()"
          (pageSize)="searchModel.pageSize = $event;  loadData_baitin_khac()"
          [currentPage]="searchModel.currentPage"
          [totalPages]="LstbaivietCT.data.totalPage"

        >
        </ngx-pagination>
      </div>
    </div>

  </div>
</nb-card-body>
<nb-card-footer class="text-end">
  <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
      </div>
  </div>

</nb-card-footer>
</nb-card>
