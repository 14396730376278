import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { PAGE_SIZE_CUSTOM, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { PageModel } from '../../../../@core/data/app-home.service';
import { WebxemthemService } from './../../../../modules/web-home/web-xemthemtintuc/web-xemthemtintuc.service';

@Component({
  selector: 'ngx-web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  id: any = 0;
  idloaibaiviet: any = '0';
  constructor(
    private webxemthemService: WebxemthemService,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
      this.idloaibaiviet = params['idloaibaiviet'];
    });
  }

  ngOnInit(): void {
    // this.loadData_footer();
    // this.loadData_by_id();
  }
  theHtmlStringNoiung: any;
  urlHost = environment.APP_HOME_URL;
  basePath = environment.APP_HOME_PATH;
  HOST = environment.PATH_IMAGE;
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: 'VN',
  });
  LstFooter: any = {
    data: [],
  };
  noidungbai: any = {
    data: [],
  };
  // loadData_footer() {
  //   this.searchModel.condition = '294';
  //   this.webxemthemService.pagingCustom(this.searchModel).subscribe((res) => {
  //     if (res.code === SUCCESS_RESPONE) {
  //       this.LstFooter = res.data.data;
  //     }
  //   });
  // }

  // loadData_by_id() {
  //   this.service.getbyid(662).subscribe((res) => {
  //     if (res.code === SUCCESS_RESPONE) {
  //       this.noidungbai = res.data;
  //       this.theHtmlStringNoiung = this.noidungbai.noidung;
  //     }
  //   });
  // }

  rss() {
    // this.service.rssFeed().subscribe(res => {
    //     if (res.statusCode === HttpStatusCode._200) {
    //         console.log(res);
    //     }
    // });
  }
}
