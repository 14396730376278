import {
  LoaibaivietApiResponse,
  NoidungbaivietListApiResponse,
  PageModel,
  QldtApiService,
  StringApiResponse,
  TintucVmIEnumerablePageModelViewApiResponse,
} from './../../../@core/data/app-home.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebxemthemService {
  this: any;
  language: any;
  constructor(
    private api: QldtApiService,
  ) {}

  // getAllTintucsk(): Observable<TintucskvmApiResponse> {
  //   return this.api.appWebHomeApiWebHomeGetAllTintucsk();
  // }


  // pagingCustom(
  //   body: PageModel
  // ): Observable<TintucVmIEnumerablePageModelViewApiResponse> {
  //   return this.api.appWebHomeApiWebHomePagingCustom(body);
  // }

  pagingCustomXemThem(
    body: PageModel
  ): Observable<TintucVmIEnumerablePageModelViewApiResponse> {
    return this.api.appWebHomeApiV1WebHomePagingCustomXemThem(body);
  }

  GetTenLBV(key: string,): Observable<LoaibaivietApiResponse> {
    return this.api.appWebHomeApiV1WebHomeGetNameLoaiBV(key);
  }
}
