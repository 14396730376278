import {
  PageModel,
  ObjectIEnumerableApiResponse,
  ObjectIEnumerablePageModelViewApiResponse,
  QldtApiService,
  // LienheModelApiResponse,
  // LienheModel,
} from './../../../@core/data/app-home.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class HoidapService {
  constructor(
    private qldtApiService: QldtApiService,
  ) {}

  // pagingHoidap(
  //   body: PageModel
  // ): Observable<HoidapModelIEnumerablePageModelViewApiResponse> {
  //   return this.api.appAdminApiDmHoidapPagingHoidap(body);
  // }
  // pagingcustomHoidap(
  //   body: PageModel
  // ): Observable<HoidapModelIEnumerablePageModelViewApiResponse> {
  //   return this.api.appAdminApiDmHoidapPagingCustom(body);
  // }
  // CreateHoidap(entity: HoidapModel): Observable<HoidapModelApiResponse> {
  //   return this.api.appWebHomeApiWebHomeCreateHoidap(entity);
  // }

  // getbyid(id: any): Observable<DmHoidapEntityApiResponse> {
  //   return this.api.appAdminApiDmHoidapSelectOne(id);
  // }
  // getHoidap(): Observable<ObjectIEnumerableApiResponse> {
  //   return this.api.appAdminApiDmHoidapSelectAll();
  // }

  // Getsysunitlist(): Observable<UnitSelectModelIEnumerableApiResponse> {
  //   return this.qthtapi.appQthtApiSysunitGetsysunitlist();
  // }
  // Getsysunitsellectall():Observable<ObjectIEnumerableApiResponse> {
  //   return this.qthtapi.appQthtApiSysunitSelectall();
  // }
  // GetLsTrangthai(): Observable<ObjectIEnumerableApiResponse> {
  //   return this.api.appAdminApiDmTrangthaiSelectAll();
  // }
  // getDonvibymacha(macha: string | undefined): Observable<SysUnitInfoModelIEnumerableApiResponse> {
  //   return this.qthtapi.appQthtApiSysunitSelectdonvibymacha(macha);
  // }
  // DmHoidapGetFile(id: number): Observable<AttFileattachmentEntityListApiResponse> {
  //   return this.api.appAdminApiDmHoidapGetFile(id);
  // }
  

}
