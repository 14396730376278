import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebAnhComponent } from './web-page-anh.component';

export const routes: Routes = [
  {
    path: '',

    component: WebAnhComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebchitietanhRoutingModule {
}
