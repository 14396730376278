import { NgForm } from '@angular/forms';
import {
  // DmHoidapEntity,
  PageModel,
} from './../../../@core/data/app-home.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../@core/customs/common.service';
// import { UnitSelectModel } from '../../../@core/data/app-qtht.service';
import { PAGE_SIZE_CUSTOM, SUCCESS_RESPONE } from '../../../@core/customs/constants';
import { HoidapService } from '../web-hoidap/web-hoidap.service';
// import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-web-hoidapchitiet',
  templateUrl: './web-hoidapchitiet.component.html',
  styleUrls: ['./web-hoidapchitiet.component.scss']
})
export class WebHoidapchitietComponent implements OnInit {
  idloaibaiviet: any = '0';
  id: any = 0;
  loaihd: string;
  unitcode: any = '0';
  basePath = environment.APP_HOME_PATH;
  constructor(
    private service: HoidapService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
      this.idloaibaiviet = params['idloaibaiviet'];
      this.unitcode = params['unitcode'];
      this.loaihd = params['loaihd'];
    });
  }


  currentDate = new Date();
  LstbaivietCT: any = {
    data: [],
  };
  // lstUnit: UnitSelectModel[];
  lstUnitAll: any[];
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: 5,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: 'VN',
  });
  chitietbaitin: any = {
    data: [],
  };
  unitcode1 : string;
  // target: DmHoidapEntity = new DmHoidapEntity();

  ngOnInit(): void {
    this.loadData_baitin_khac();
    this.getUnit();
    this.getallUnit();
  }
  getTenUnit(value: string) {
    if (value === null) return null;
    const name = this.lstUnitAll.find((x) => x.unitcode === value)?.tendonvi;
    return name;
  }

  loadData_baitin_khac() {
    this.searchModel.condition = this.unitcode;
    this.searchModel.sortColumn = this.loaihd;
    // this.service.pagingcustomHoidap(this.searchModel).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.LstbaivietCT.data = res.data;
    //   }
    // });
  }

  getUnit() {
    // this.service.Getsysunitlist().subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.lstUnit = result.data;
    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }
getallUnit() {
  // this.service.Getsysunitsellectall().subscribe((result) => {
  //   if (result.code === SUCCESS_RESPONE) {
  //     this.lstUnitAll = result.data;

  //   } else {
  //     this.commonService.toastrDanger(result.message);
  //   }
  // });
}
  loadData_by_id() {
    // this.service.getbyid(this.id).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.chitietbaitin = res.data;
    //   }
    // });
  }

  clickloadtintuc(id: number,loaihd: number) {
    this.router.navigate(["web-home/hoidapphanhoi/" + id +'/'+loaihd]).then(() => {
      this.id = id;
      this.loadData_by_id();
    });
  }
}
