<div class="container-fluid">
  <div class="container">
    <div class="row">
      <h2 *ngIf="idloaibaiviet === '01'" class="head-danh-muc">
        <div class="field__item">TIN TỨC - SỰ KIỆN</div>
      </h2>
      <h2 *ngIf="idloaibaiviet !== '01'" class="head-danh-muc">
        <div class="field__item">{{this.title}}</div>
      </h2>
      <hr style="border: 3px solid #006cff" />
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12" *ngFor="let item of LstbaivietCT">
        <div style="width: 100%; border: solid 1px #bbbbbb; padding: 10px; float: left; margin-bottom: 10px;">
          <a routerLink="/web-home/{{link}}/{{item.id}}/{{item.loaibaiviet}}/{{item.luongsukien? item.luongsukien : '0'}}"
            style="font-size: 100%">
              <div class="col-md-3 col-lg-3 me-3" style="width: 25%;padding-top: 20%;position: relative;float: left;overflow: hidden;background-color: #ddd;">
                <img style="width: 100%; height: 100%; position: absolute; top: 50%;left: 50%;transform: translate(-50%, -50%);opacity: 0.85;" 
                *ngIf="checkImage && item.thumb" class="clearfix rounded" 
                [src]="HOST + (item.thumb ? item.thumb.replace('FileProjectBT', 'File') : '')" alt="Image"  />
              </div>
              <div class="col-md-12 col-lg-12">
                <h5>{{ item.tieude }}</h5>
                <time style="color: gray">{{item.ngayxuatban | date: "dd/MM/yyyy HH:mm:ss"}}</time><br />
                <div *ngIf="item.noidungtomtat">
                  <p style="
                  line-height: 1.3;
                  font-size: 14px;
                  font-family: sans-serif, Arial !important;
                " [style.color]="item.form === 1 ? 'blueviolet' : ''" 
                  [ngClass]="item.form === 1 ? 'text-center align-middle' : ''" [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.noidungtomtat)">
                  </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div style="justify-content:center; display: flex;">
        <ngx-pagination (pageChange)="searchModel.currentPage = $event; loadData_baitin_khac()"
          (pageSize)="searchModel.pageSize = $event; loadData_baitin_khac()" [currentPage]="searchModel.currentPage"
          [totalPages]="LstbaivietCTPage.totalPage">
        </ngx-pagination>
      </div>
    </div>
  </div>
</div>