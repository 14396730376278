import { HomeLayoutsComponent } from './../../@theme/layouts/home-layouts/home-layouts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { routes as tongquanRoutes } from './web-tongquan/web-tongquan-routing.module';
import { routes as chitiettintucRoutes } from './web-chitiettintuc/web-chitiettintuc-routing.module';
import { routes as xemthemtintucRoutes } from './web-xemthemtintuc/web-xemthemtintuc-routing.module';
import { routes as tracuudiemthiRoutes } from './web-tracuudiemthi/web-tracuudiemthi-routing.module';
import { routes as lienheRoutes } from './web-lienhe/web-lienhe-routing.module';
import { routes as timkiemRoutes } from './web-timkiem/web-timkiem-routing.module';
import { routes as hoidapRoutes } from './web-hoidap/web-hoidap-routing.module';
import { routes as hoidapphanhoiRoutes } from './web-hoidapphanhoi/web-hoidapphanhoi-routing.module';
import { routes as hoidapchitietRoutes } from './web-hoidapchitiet/web-hoidapchitiet-routing.module';
import { routes as vanbanRoutes } from './web-vanban/web-vanban-routing.module';
import { BodyComponent } from './body/body.component';
import { routes as WebImageRoutes } from './web-page-anh/web-page-anh-routing.module';
import { WebVanbanchitietComponent } from './web-vanbanchitiet/web-vanbanchitiet.component';
import { routes as chuyendoiDLRoutes } from './web-chuyendoiDL/web-chuyendoiDL-routing.module';
import { routes as videoRoutes } from './web-page-video/web-page-video-routing.module';

export const routes: Routes = [
  {
    path: '',
    component: HomeLayoutsComponent,
    children: [
      { path: '', component: BodyComponent },
      { path: 'tintucgioithieu', children: tongquanRoutes },
      { path: 'chitiettintuc', children: chitiettintucRoutes },
      { path: 'xemthemtintuc', children: xemthemtintucRoutes },
      { path: 'tracuudiem', children: tracuudiemthiRoutes },
      { path: 'lienhe', children: lienheRoutes },
      { path: 'timkiem/:search', children: timkiemRoutes },
      { path: 'hoidap', children: hoidapRoutes },
      { path: 'hoidapphanhoi', children: hoidapphanhoiRoutes },
      { path: 'hoidapchitiet', children: hoidapchitietRoutes },
      { path: 'anh', children: WebImageRoutes },
      { path: 'vanban', children: vanbanRoutes },
      { path: 'video', children: videoRoutes },
      { path: 'chitietvanban/:type', component: WebVanbanchitietComponent },
      { path: 'chuyendoiDL', children: chuyendoiDLRoutes },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebHomeRoutingModule {
}
