<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Mã QR bài viết</h5>
      </div>
      <div class="modal-body">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <div class="k-text-center">
            <div class="k-card-body">
              <kendo-qrcode value="{{ UrlParam }}" errorCorrection="M">
              </kendo-qrcode>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Đóng
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-3 bg-white" style="margin-top: 25px">
      <h4 style="color: #006cff">LOẠI TIN</h4>
      <ul class="normal-list treeview">
         <li><span (click)="clickXemTin('01')">Tin tức sự kiện</span></li>
        <li class="contains-items" (click)="toggleMenu($event)">Hoạt động đào tạo
          <ul class="normal-list">
            <li><a (click)="clickXemTin('031')">Các chương trình đào tạo</a></li>
            <li><a (click)="clickXemTin('032')">Kế hoạch đào tạo bồi dưỡng</a></li>
            <li><a (click)="clickXemTin('033')">Tổ chức đào tạo bồi dưỡng</a></li>
            <li><a (click)="clickXemTin('034')">Hoạt động đào tạo bồi dưỡng</a></li>
          </ul>
        </li>
        <li class="contains-items" (click)="toggleMenu($event)">Nghiên cứu khoa học
          <ul class="normal-list">
            <li><a (click)="clickXemTin('041')">Hội thảo khoa học</a></li>
            <li><a (click)="clickXemTin('042')">Các đề tài nghiên cứu khoa học cấp Bộ</a></li>
            <li><a (click)="clickXemTin('043')">Các chương trình nghiên cứu của Trường</a></li>
            <li><a (click)="clickXemTin('044')">Chương trình nghiên cứu cấp nhà nước</a></li>
          </ul>
        </li>
        <li class="contains-items" (click)="toggleMenu($event)">Hợp tác quốc tế
          <ul class="normal-list">
            <li><a (click)="clickXemTin('051')">Chương trình đào tạo nước ngoài</a></li>
            <li><a (click)="clickXemTin('052')">Chương trình đào tạo trong nước</a></li>
            <li><a (click)="clickXemTin('053')">Hội thảo quốc tế</a></li>
          </ul>
        </li>
      </ul>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-hover" style="width: 98%">
            <h4 style="color: #006cff">TIN KHÁC</h4>
            <tbody style="padding: 10px">
              <tr *ngFor="let item of LstTinKhac" class="cs">
                <td>
                  <a type="button" style="font-size: 14px; font-family:sans-serif,Arial!important;"
                   (click)="clickloadtintuc(item.id, item.loaibaiviet, item.luongsukien)">
                    {{ item.tieude ?? '' }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-9" id="print-section">
      <h4 style="color: #006cff; margin-top: 25px; text-transform: uppercase;">
        <span *ngIf="title ">
          {{ title }}
        </span>
        <span *ngIf="!title" >
          TIN TỨC - SỰ KIỆN
         </span>
      </h4>
      <hr  style="border: 3px solid #006cff" />
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <h2>
            <span class="article-content">
              {{ chitietbaitin.tieude ?? '' }}
            </span>
          </h2>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <div >
            <time style="color: gray; margin-top: 0px; margin-left: 10px;">{{
              chitietbaitin.ngayxuatban | date : "dd/MM/yyyy HH:mm" }}</time>
          </div>
        </div>

        <!-- Button trigger modal -->

        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <div style="float: right">
            <button type="button" class="button1" title="Mã QR bài viết" data-bs-toggle="modal"
              data-bs-target="#exampleModal">
              <i class="fa-solid fa-qrcode pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Facebook" shareButton="facebook">
              <i class="fa-brands fa-facebook pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Twitter" shareButton="twitter">
              <i class="fa-brands fa-twitter pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Email" shareButton="email">
              <i class="fa-solid fa-envelope pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Telegram" shareButton="telegram">
              <i class="fa-brands fa-telegram pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Linkedin" shareButton="linkedin">
              <i class="fa-brands fa-linkedin pm btt"></i>
            </button>
            &nbsp;
            <button title="Print and Export" class="bt" printSectionId="print-section" [useExistingCss]="true" ngxPrint>
              <i class="fa-solid fa-print pm"></i>
            </button>
          </div>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <div style="float: right;" *ngIf="chitietbaitin.form !== 1">
            <button class="button1" title="Đọc văn bản">
              <i class="fa-light fa-volume pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Độ sáng">
              <i class="fa-light fa-sun-bright pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Xem cỡ chữ">
              <i class="fa-solid fa-font pm btt"></i>
            </button>
            &nbsp;
            <button class="button1" title="Xem cỡ chữ">
              <i class="fa-solid fa-font pn btt"></i>
            </button>
          </div>
        </div>
      </div>
      <br />
      <article>
        <section class="header">
          <header>
            <div class="noidungtomtat">
              <strong>{{ chitietbaitin.noidungtomtat ?? '' }}</strong>
            </div>
          </header>
        </section>
        <section class="image-container" style="overflow-x: auto;">
          <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(chitietbaitin.noidung)"></div>
        </section>
      </article>
      <div class="text-center align-middle">
        <hr />
        <strong>{{ chitietbaitin.tentacgia ?? '' }}</strong>
      </div>
      <div class="row" style="text-align: end;">
        <span>Lượt xem: {{ chitietbaitin.viewnum }}</span>
      </div>
    </div>
 
  </div>

</div>
<button (click)="topFunction1()" id="myBtn" title="Go to top" style="opacity: 0.5">
  <img class="rounded justify-content-center" [src]="basePath + '/assets/images/banner/uparrow.png'"
    alt="IMG" style="width: 30px" />
</button>