<div class="row" style="margin-top: 10px;">
  <div class="container">
    <div class="row vanban_search">
      <div class="col-md-6 col-xs-6 col-lg-6 col-xl-6">
        <div class="form-group">
            <label class="control-label">Tìm kiếm</label>
            <div class="form-group">
              <input autocomplete="off" type="text" class="form-control" name="sohieu" [(ngModel)]="searchModel.search" 
              placeholder="Nhập số hiệu, trích yếu, ..." />
            </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-3 col-lg-3 col-xl-3">
        <div class="form-group">
          <label class="control-label" style="margin-top: 10px;">Lĩnh vực văn bản</label>
          <div class="form-group">
            <select id="categorySelect" class="form-control" >
              <option value="">--Chọn lĩnh vực văn bản</option>
              <option *ngFor="let item of lstLVVB" name="hinhthucvanban" [value]="item.matudien">
                {{item.tentudien}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-3 col-lg-3 col-xl-3">
        <div class="form-group">
          <label class="control-label" style="margin-top: 10px;">Loại văn bản</label>
          <div class="form-group">
            <select id="categorySelect1" class="form-control" [(ngModel)]="searchModel.search">
              <option value="">--Chọn loại văn bản</option>
              <option *ngFor="let item of lstLVB" name="loaivanban" [value]="item.matudien" >
                {{item.tentudien}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 text-center">
        <button style="margin-top: 10px;" type="button" class="btn btn-primary justify-content-center"
        (click)="loadData()">Tìm kiểm</button>
      </div>
    </div>

    <div class="col-md-12" style="padding-right: 20px">

      <div class="row control-label">
        <div class="col-12" style="overflow: auto">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr style="vertical-align: middle;">
                        <th class="text-center" style="width: 50px">STT</th>
                        <th class="text-center" style="min-width: 100px; width: auto">Số hiệu</th>
                        <th class="text-center" style="min-width: 200px; width: auto">Trích yếu</th>
                        <th class="text-center" style="min-width: 100px; width: auto">Ngày ban hành</th>
                        <th class="text-center" style="min-width: 100px; width: auto">Ngày hiệu lực</th>
                        <!-- <th class="text-center" style="min-width: 100px; width: auto">Trạng thái</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!LstVanban.recordsCount">
                        <td colspan="5">Không có dữ liệu</td>
                    </tr>
                    <tr *ngFor="let item of LstVanban.data; let i = index">
                        <td class="text-center">{{ (searchModel.currentPage - 1) * searchModel.pageSize + i + 1 }}</td>
                        <td class="text-left click" (click)="clickloadvanban(item.id)">{{ item.sohieu}}</td>
                        <td class="text-left click">{{ item.trichyeu }}</td>
                        <td class="text-center">{{ item.ngaybanhanh | date: "dd/MM/yyyy" }}</td>
                        <td class="text-center">{{ item.ngayhieuluc | date: "dd/MM/yyyy" }}</td>
                        <!-- <td class="text-center">{{ item.hieuluc === 1 ? 'Có hiệu lực' : '' }}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    
      <div *ngIf="LstVanban?.recordsCount > 0" style="justify-content:center; display: flex;">
        <ngx-pagination
          (pageChange)="searchModel.currentPage = $event; loadData()"
          (pageSize)="searchModel.pageSize = $event; loadData()"
          [currentPage]="searchModel.currentPage"
          [totalPages]="LstVanban.totalPage" >
        </ngx-pagination>
      </div>
    </div>
  </div>
</div>
  
  