import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebHoidapphanhoiComponent } from './web-hoidapphanhoi.component';

export const routes: Routes = [
  {
    path: ':id/:loaihd',
    component: WebHoidapphanhoiComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebHoidapphanhoiRoutingModule {
}
