import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonService } from "../../../@core/customs/common.service";
import { environment } from "../../../../environments/environment";
import { PageModel, QldtApiService, TintucSukienEntity } from "../../../@core/data/app-home.service";
import {
  PAGE_SIZE_CUSTOM,
  SUCCESS_RESPONE,
} from "../../../@core/customs/constants";
@Component({
  selector: "ngx-web-chitiettintuc",
  templateUrl: "./web-chitiettintuc.component.html",
  styleUrls: ["./web-chitiettintuc.component.scss"],
})
export class WebChitiettintucComponent implements OnInit, OnDestroy {
  id: any = 0;
  idloaibaiviet: any = "0";
  idluongsukien: any = "0";
  constructor(
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private dialog: MatDialog,
    private router: Router,
    private service: QldtApiService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.idloaibaiviet = params["idloaibaiviet"];
      this.idluongsukien = params["idluongsukien"];
    });
  }
  basePath = environment.APP_HOME_PATH;

  LstbaivietCT: any = {
    data: [],
  };
  Lstluongsk: any = {
    data: [],
  };
   HOST = environment.PATH_IMAGE;
  target: TintucSukienEntity = new TintucSukienEntity();
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: "VN",
  });
  checkVideo : boolean = false;
  checkImage : boolean = false;
  chitietbaitin: any = {
    data: [],
  };
  LstTinKhac: any = {
    data: [],
  };
  title : string;
  theHtmlStringNoidung: any;
  view: any;
  UrlParam: string;
  ListVideoOrImage: any[] = [];
  ListActive: any[] = [];
  convertRomanNumber(number) {
    const defaultValue = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
    ];
    let result = "";
    while (number > 10) {
      const temp = Number((number / 10).toFixed(0));
      result += defaultValue[temp - 1].replace(/I/g, "X").replace(/V/g, "L");
      number = number % 10;
    }
    result += number > 0 ? defaultValue[number - 1] : "";
    return result;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.UrlParam = environment.APP_HOST_HOME + '/web-home/chitiettintuc' + '/' + this.id + '/' + this.idloaibaiviet;
    this.loadData_by_id();    
  }
  
  ngOnDestroy() {
  }
  
  loadData_by_id() {
    this.service.appWebHomeApiV1WebHomeGetbaiviet(this.id).subscribe( res => {
      if (res.code === SUCCESS_RESPONE) {
        this.chitietbaitin = res.data;
        this.theHtmlStringNoidung = this.chitietbaitin.noidung;
        this.updateview(this.chitietbaitin.id);
      }
    })
    this.service.appWebHomeApiV1WebHomeGetNameLoaiBV(this.idloaibaiviet).subscribe((res) =>{
      if (res.code === SUCCESS_RESPONE) {
        this.title = res.data.title; 
      }
      
    })
    // Load Tin khác
    this.searchModel.condition2 = this.idloaibaiviet;
    this.service.appWebHomeApiV1WebHomePagingCustomCacTinKhac(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.LstTinKhac = res.data.data;
        this.LstTinKhac = this.LstTinKhac.filter((x) => x.id !== this.chitietbaitin.id).slice(0, 10);
      }
    });
  }
 
  updateview(id: number) {
    this.service.appWebHomeApiV1WebHomeUpdatetintuc(id).subscribe((res) => {});
  }

  clickXemTin(loaibaiviet: string):void {
    this.router.navigate(['web-home/xemthemtintuc/' + loaibaiviet])
  }

  clickloadtintuc(id: number, loaibaiviet: string, luongsukien: string) {
    if (luongsukien === "") {
      this.router
        .navigate([
          "web-home/chitiettintuc/" + id + "/" + loaibaiviet + "/" + '0',
        ])
        .then(() => {
          window.location.reload();
        });
    } else {
      this.router
        .navigate([
          "web-home/chitiettintuc/" +
            id +
            "/" +
            loaibaiviet +
            "/" +
            luongsukien,
        ])
        .then(() => {
          window.location.reload();
        });
    }
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (
      document.body.scrollTop > 40 ||
      document.documentElement.scrollTop > 40
    ) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction1() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  toggleMenu(event: Event) {
    const element = event.currentTarget as HTMLElement;
    element.classList.toggle('open');
  }
}
