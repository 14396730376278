export const SUCCESS_RESPONE = 'Success';
export const DUPLICATE_RESPONE = 'Duplicate';
export const NOTFOUND_RESPONE = 'NotFound';
export const SUCCESS_NOTICE = 'Thành công';
export const TITLE_NOTICE = 'Thông báo';

export const PAGE_SIZE = 12;
export const PAGE_SIZE_CUSTOM = 12;

export const WIDTH_DIALOG = '80%';
export const HEIGHT_DIALOG = '100%';
export const SEARCH_LABEL = 'Tìm kiếm';
export const REFRESH_LABEL = 'Làm mới';
export const ADDNEW_LABEL = 'Thêm mới';
export const EDIT_LABEL = 'Sửa';
export const DETAIL_LABEL = 'Xem';
export const DELETE_LABEL = 'Xóa';

export const TYPE_ANH_VIDEO = {
  ANH: 0,
  ANH_HOME: 1,
  VIDEO: 2
}

export const TYPE_VB = {
  LVB : 'LVB',  //Loại văn bản
  LVVB: 'LVVB'  // Lĩnh vực văn bản
}


export const LBL_DISPLAY = {
  syncNgsp: 'Đồng bộ NGSP',
  add: 'Thêm mới',
  edit: 'Sửa',
  update: 'Cập nhật',
  delete: 'Xóa',
  view: 'Xem',
  search: 'Tìm kiếm',
  save: 'Lưu',
  print: 'In',
  close: 'Đóng',
  back: 'Quay lại',
  refresh: 'Làm mới',
  file: 'Chọn file',
  fileEN: 'Select file',
  confirm: 'Xác nhận',
  cancel: 'Hủy',
  importFile: 'Import',
  downloadTemplate: 'Tải template',
  download: 'Tải xuống',
  sync: 'Đồng bộ',
  chooseImportFile: 'Chọn file import',
};

export const CDS = '33,34,35,36,37,38,39,40,41,42,43'; // nhóm tin tức chuyển đổi số
export const HTCT = '20,21,22,23,24,25,26,27,28,29'; // nhóm tin tức Hệ thống chính trị
export const TTSK = '01,04,06,07,08,09,10,11,12,16,30,328,4032,330,4033,6060,6061,4007,4008,4009,4010,6062,15'; // nhóm tin tức sự kiện
export const TTCD =
  '70,71,72,73,80,81,82,83,84,90,91,92,93,97,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,120,121,122,123,124,125,130,131,132,140,141,150,151,152,153,154,155,156,157,158,160,161,162,163,164,170,171,172,180,181,182,190,191,192,193,200,201,202,203,204,210,211,212,213,214,220,221,222,223,224,225,226,227,228,229,230,231,240,241,242,243,253,254,3017,3018,3019,3020,3021'; // nhóm tin tức thông tin chuyên đề
  export const BNCM = '50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,66,67,68,69,244,255'; // nhóm banner chuyên mục
  export const TTDD = '322,321,320,319,318,317,316,315,314,313,312,311,310,309,308,307'; // nhóm thông tin đất đai
  export const CTCV = '287,288,289,290,291'; // nhóm chuyên trang covid
  export const TTCCD = '50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69'; // nhóm thông tin đất đai
  export const NT = '305'; // nhóm thông tin nguồn tin
  export const QH = '311,312,313'; // nhóm thông tin quy hoạch đất đai
  export const KH = '314,315,316'; // nhóm thông tin kế hoạch sử dụng đất
  export const KGD = '308'; // nhóm Khung giá đất
  export const DG = '307'; // nhóm Đấu giá đất
  export const QHKH = '333,334,335,336,337,338,339,340,341,342,343'; // nhóm tin tức quy hoạch kế hoạch
  export const TTQH = '334'; // nhóm Thông tin quy hoạch
  export const KHGD = '347'; // nhóm Kế hoạch giai đoạn
  export const CTDA = '343'; // nhóm Chương trình đề án
  export const CQTH = 'SO_CONG_THUONG,SO_GD_VA_DT,SO_GT_VT,SO_KHOA_HOC_VA_CONG_NGHE,SO_KE_HOACH_VA_DAU_TU,SO_LAO_DONG_THUONG_BINH_VA_XH,SO_NGOAI_VU,SO_NOI_VU,SO_NN_VA_PTNN,SO_TAI_CHINH,SO_TN_VA_MT,SO_TT_VA_TT,SO_TU_PHAP,SO_VH_TT_VA_DL,SO_XAY_DUNG,SO_Y_TE,BDT_PTO,BAN_QUAN_LY_CAC_KHU_CONG_NGHIEP,CONG_AN_TINH_TG,btt_cap_huyen,btt_cap_xa,BAO_HIEM_XA_HOI'; // nhóm Cơ quan thực hiện

export const TYPES_FILE_ACCEPT = [
  'image/jpeg',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'text/plain',
  'application/zip',
  'application/pdf',
  'application/vnd.rar',
  'application/x-tar',
  'image/gif',
]

export const LST_STATE = [
  {
      text: 'Hoàn thành',
      value: 1,
      color: 'text-success'
  },
  {
      text: 'Chưa chạy',
      value: 0,
      color: 'text-dark'
  },
  {
      text: 'Đang chạy',
      value: 2,
      color: 'text-primary'
  },
  {
      text: 'Đã hủy',
      value: -1,
      color: 'text-danger'
  },
];
export const TYPE_MENU = {
  PHIEUDIEUTRA: 10,
  PHIEUDIEUTRA_ND: 11,
  HOIDAP: 12,
  LIENHE: 13,
  BANNER: 4,
};