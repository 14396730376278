  <div class="row hd1">
    <div class="col-md-3 bg-white" style="margin-top: 20px">
      <div
      class="container-fluid"
      style="
        margin-top: 25px;
        padding: 10px 10px 5px 5px;
        border: 1px solid #ccc;
        background-color: white;
      "
    >
      <h4 style="color: red !important">
        <strong>ĐƠN VỊ TRẢ LỜI</strong>
      </h4>
      <hr />

      <p>
        <a  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
          <h6><i class="fa-solid fa-caret-down"></i>&nbsp; Sở, Ban ngành</h6>
        </a>
      </p>
      <div class="collapse" id="collapseExample">

          <p *ngFor="let item of ListSoBanNganh">
            <a
              [routerLink]="['/web-home/hoidapchitiet', item.unitcode, 0]"
              style="margin-top: 20px; cursor: pointer; color:#828081"
            >
              {{ getTenUnit(item.unitcode) }}
            </a>
          </p>
      </div>
      <p>
        <a  data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
          <h6><i class="fa-solid fa-caret-down"></i>&nbsp; Thành phố, huyện, thị xã</h6>
        </a>
      </p>
      <div class="collapse" id="collapseExample1">

          <p *ngFor="let item of ListHuyenthanhthi">
            <a
              [routerLink]="['/web-home/hoidapchitiet', item.unitcode, 0]"
              style="margin-top: 20px; cursor: pointer; color:#828081"
            >
              {{ getTenUnit(item.unitcode) }}
            </a>
          </p>
      </div>
      <p>
        <a  data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
          <h6><i class="fa-solid fa-caret-down"></i>&nbsp; Đơn vị khác</h6>
        </a>
      </p>
      <div class="collapse" id="collapseExample2">

          <p *ngFor="let item of ListDonvikhac">
            <a
              [routerLink]="['/web-home/hoidapchitiet', item.unitcode, 0]"
              style="margin-top: 20px; cursor: pointer; color:#828081"
            >
              {{ getTenUnit(item.unitcode) }}
            </a>
          </p>
      </div>
    </div>
    </div>

    <div class="col-md-9">
      <h3 style="color: #006cff; margin-top: 25px">HỎI ĐÁP TRỰC TUYẾN</h3>
      <hr style="border: 3px solid #006cff" />
      <div>
        <div class="row">
          <h5 style="color: #815aa7">Tiêu đề câu hỏi</h5>

          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
            <div class="form-group">
              {{ chitietbaitin.tieude }}
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
            <div class="form-group">
              <p style="color: #8c8c8c">
                Ngày gửi : {{ chitietbaitin.time | date: "dd/MM/yyyy" }}
              </p>
            </div>
          </div>

          <h5 style="color: #815aa7">Nội dung câu hỏi</h5>
          <p>
            Người hỏi: <strong>{{ chitietbaitin.tennguoigui }}</strong>
          </p>
          <p>
            Số điện thoại: <strong>{{ chitietbaitin.dienthoai }}</strong>
          </p>
          <p>
            Địa chỉ: <strong>{{ chitietbaitin.diachi }}</strong>
          </p>
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
            <div class="form-group" [innerHTML]="
            sanitizer.bypassSecurityTrustHtml(chitietbaitin.noidung)
          ">
              <!-- {{ chitietbaitin.noidung }} -->
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div style="width: 150px; float: left;">
                <p >File đính kèm :</p>
              </div>
              <div style="width: 400px;">
                <ul *ngIf="selectedFiles && selectedFiles.length > 0" style="list-style: none; display: contents">
                  <li class="file-x" *ngFor="let file of selectedFiles">
                      <a href="{{HREF + file.filename}}" title="{{ file.filename }}">{{ file.filename }}</a>
                  </li>
                </ul>
                <span *ngIf="selectedFiles.length === 0"><i>Không có file đính kèm</i></span>
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="input-group mb-3"></div>
          </div>
        </div>
      </div>
      <h3 style="color: #006cff; margin-top: 25px">KẾT QUẢ PHẢN HỒI</h3>
      <hr style="border: 3px solid #006cff" />
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
        <div class="form-group">
          <p style="color: #8c8c8c">
           Trạng thái xử lí : {{getTenTrangthai(chitietbaitin.matrangthai)}}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2">
          <p class="title"><strong>Đơn vị xử lí </strong></p>
        </div>
        <div class="col-sm-10 col-md-10 col-xs-10 col-lg-10">
          <strong> {{ getTenUnit(chitietbaitin?.unitcode) }}</strong>
        </div>
        <div class="card" style="margin-bottom: 20px">
          <div class="card-body">
            <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2">
              <p class="title"><strong>Nội dung</strong></p>
            </div>
            <div class="col-sm-10 col-md-10 col-xs-10 col-lg-10">
              <div
                [innerHTML]="
                  sanitizer.bypassSecurityTrustHtml(theHtmlStringNoiung)
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<button (click)="topFunction()" id="myBtn" title="Go to top">
  <img
    class="rounded justify-content-center"
    [src]="basePath + '/assets/images/banner/uparrow.png'"
    alt="IMG"
    style="width: 50px"
  />
</button>
