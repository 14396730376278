import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../@core/customs/common.service';
import { QldtApiService } from '../../../@core/data/app-home.service';
import { SUCCESS_RESPONE } from '../../../@core/customs/constants';
import { Observable } from 'rxjs';
@Component({
  selector: 'ngx-web-vanbanchitiet',
  templateUrl: './web-vanbanchitiet.component.html',
  styleUrls: ['./web-vanbanchitiet.component.scss'],
})

export class WebVanbanchitietComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private service: QldtApiService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['type'];
    });
  }
  id: number;
  
  target: any;
    
  ngOnInit(): void {
    if(this.id) {
      this.loadData();
    }
  }
  tenlinhvuc: string;
  tenhinhthuc: string;
  loadData() {
    this.service.qldtAdminApiV1DmVanBanSelectOne(this.id).subscribe(res => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
        // Load tên lĩnh vực và hình thức văn bản
        this.loadbytudien(this.target.linhvuc).subscribe(tenlinhvuc => {
          this.tenlinhvuc = tenlinhvuc;
        });
        this.loadbytudien(this.target.hinhthucvanban).subscribe(tenhinhthuc => {
          this.tenhinhthuc = tenhinhthuc;
        });
      }
    });
  }
  TYPE_VB = '';
  lsttudien: any[] = [];
  // loadbytudien(value: string){
  //   this.service.appWebHomeApiV1WebHomeGetDmTuDienByType(this.TYPE_VB, value).subscribe(res =>{
  //     if(res.code === SUCCESS_RESPONE) {
  //       this.lsttudien = res.data;
  //       console.log(this.lsttudien);
  //     }
  //   });
  // }
  loadbytudien(value: string) {
    return new Observable<string>(observer => {
      this.service.appWebHomeApiV1WebHomeGetDmTuDienByType(this.TYPE_VB, value).subscribe(res => {
        if (res.code === SUCCESS_RESPONE) {
          const item = res.data.find(d => d.matudien === value);
          observer.next(item ? item.tentudien : value);
          observer.complete();
        } else {
          observer.error('Error loading dictionary');
        }
      });
    });
  }
}
  