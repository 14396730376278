import { WebxemthemService } from "./web-xemthemtintuc.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import {
  PAGE_SIZE_CUSTOM,
  SUCCESS_RESPONE,
} from "../../../@core/customs/constants";
import { interval } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { first } from "rxjs/operators";
import { stringify } from "querystring";
import { DomSanitizer } from "@angular/platform-browser";
import { PageModel } from "../../../@core/data/app-home.service";

@Component({
  selector: "ngx-web-xemthemtintuc",
  templateUrl: "./web-xemthemtintuc.component.html",
  styleUrls: ["./web-xemthemtintuc.component.scss"],
})
export class WebXemthemtintucComponent implements OnInit {
  idloaibaiviet: any = "0";
  formtype: number = -1;
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: WebxemthemService,
    private _http: HttpClient,
    public sanitizer: DomSanitizer,
  ) {
    // this.activatedRoute.params.subscribe((params) => {
    //   this.idloaibaiviet = params["idloaibaiviet"];
    //   this.formtype = params["formtype"] ?? -1;
    // });
  }
  imageUrl:string;
  basePath = environment.APP_HOME_PATH;
  // HOST: string = 'https://1'; 
  HOST: string = environment.PATH_IMAGE;
  LstbaivietCT: any = {
    data: [],
  };
  LstbaivietCTPage: any = {
    data: [],
  };
  title : string;
  checkImage : boolean = false;
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition2: null,
    sortColumn: null,
    sortExpression: null,
    status: 1,
    columnName: null,
    childType: null,
    language: "VN",
  });
  link: string ='chitiettintuc';
  ngOnInit(): void {
    console.log('HOST:', this.HOST); 
    this.activatedRoute.params.subscribe((params) => {
      this.idloaibaiviet = params["idloaibaiviet"];
      this.formtype = params["formtype"] ?? -1;

      this.loadData_baitin_khac();
      this.getTenLBV();

      if (sessionStorage.getItem('pageIndexXemThem')) {
        this.searchModel.currentPage = Number(sessionStorage.getItem('pageIndexXemThem'));
      }
    });
  }
  async geturl_image(thumb) {
    const url = this.HOST + thumb;
    return url;
  }
  onImgError(event){
    event.target.src = this.basePath + '/assets/images/dfimg.jpg'
   //Do other stuff with the event.HOST
   }
   getTenLBV() {
    this.service.GetTenLBV(this.idloaibaiviet).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
       this.title = res.data.title;
      }
    });
  }

  loadData_baitin_khac() {
    this.searchModel.condition2 = this.idloaibaiviet;
    this.searchModel.formType = this.formtype;
    sessionStorage.setItem('pageIndexXemThem', this.searchModel.currentPage.toString())
    this.service.pagingCustomXemThem(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.LstbaivietCT = res.data.data.slice(0,12);
        this.LstbaivietCTPage = res.data;
          res.data.data.forEach(element => {
            if (element.thumb?.includes('mp4')) {
              this.checkImage = false
          } else {
            this.checkImage = true
          }
        });
      }
    });
  }       
}
