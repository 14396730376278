import { IconDeleteComponent } from './components/template/icon/delete/icon-delete.component';
import { IconEditComponent } from './components/template/icon/edit/icon-edit.component';
import { IconInfoComponent } from './components/template/icon/info/icon-info.component';
import { BtnDeleteComponent } from './components/template/button/delete/btn-delete.component';
import { BtnFileComponent } from './components/template/button/file/btn-file.component';
import { BtnSyncComponent } from './components/template/button/sync/btn-sync.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbRadioModule,
  NbTabsetModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import {
  LayoutDirectionSwitcherComponent,
  SearchInputComponent,
  SwitcherComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { NgSelectModule } from '@ng-select/ng-select';
import { MdPaginationComponent } from './components/pagination/pagination.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { BtnAddComponent } from './components/template/button/add/btn-add.component';
import { BtnSearchComponent } from './components/template/button/search/btn-search.component';
import { BtnSaveComponent } from './components/template/button/save/btn-save.component';
import { BtnRefreshComponent } from './components/template/button/refresh/btn-refresh.component';
import { BtnCloseComponent } from './components/template/button/close/btn-close.component';
import { BtnBackComponent } from './components/template/button/back/btn-back.component';
import { BtnSubmitComponent } from './components/template/button/submit/btn-submit.component';
import { BtnAddSyncComponent } from './components/template/button/add-sync/btn-add-sync.component';
import { BtnUpdateSyncComponent } from './components/template/button/update-sync/btn-update-sync.component';
import { BtnDownloadComponent } from './components/template/button/download/btn-download.component';
import { IconApproveComponent } from './components/template/icon/approve/icon-approve.component';
import { IconCheckComponent } from './components/template/icon/check/icon-check.component';
import { IconDeclineComponent } from './components/template/icon/decline/icon-decline.component';
import { IconReplyComponent } from './components/template/icon/reply/icon-reply.component';
import { IconShareComponent } from './components/template/icon/share/icon-share.component';
import { BtnImportFileComponent } from './components/template/button/import-file/btn-import-file.component';
import { BtnDownloadTemplateComponent } from './components/template/button/download-template/btn-download-template.component';
import { BtnChooseFileExcelComponent } from './components/template/button/choose-file-excel/btn-choose-file-excel.component';
import { BtnPrintComponent } from './components/template/button/print/btn-print.component';
import { BtnConfirmComponent } from './components/template/button/confirm/btn-confirm.component';
import { IconResetPassComponent } from './components/template/icon/reset-pass/icon-reset-pass.component';
import { IconCommentComponent } from './components/template/icon/comment/icon-comment.component';
import { IconSiteMapComponent } from './components/template/icon/site-map/icon-site-map.component';
import { BtnCancelComponent } from './components/template/button/cancel/btn-cancel.component';
import { BtnDeleteRowComponent } from './components/template/button/delete-row/btn-delete-row.component';
import { BtnAddRowComponent } from './components/template/button/add-row/btn-add-row.component';
import { NumberWithDotPipe } from './pipes/number-with-dot.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { SortDirective } from '../@core/utils/sort.directive';
import { ConvertDatePipe, CustomTextPipe } from '../@core/customs/common.service';
import { SendComponent } from './components/template/icon/send/send.component';
import { DialogConfirmComponent } from './components/template/dialog/dialog-confirm/dialog-confirm.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { BtnFileENComponent } from './components/template/button/fileEN/btn-fileEN.component';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  NgSelectModule,
  ReactiveFormsModule,
];

const MAT_MODULES = [MatDialogModule, MatTabsModule, MatSlideToggleModule, MatCardModule];

const NB_MODULES = [
  NbRadioModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbTabsetModule,
  TreeViewModule

];
const COMPONENTS = [
  IconSiteMapComponent,
  IconCommentComponent,
  BtnConfirmComponent,
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  SearchInputComponent,
  MdPaginationComponent,
  BtnCancelComponent,
  BtnSubmitComponent,
  BtnAddComponent,
  BtnBackComponent,
  BtnCloseComponent,
  BtnRefreshComponent,
  BtnSaveComponent,
  BtnSearchComponent,
  BtnSyncComponent,
  BtnFileComponent,
  BtnFileENComponent,
  BtnDeleteComponent,
  BtnAddSyncComponent,
  BtnUpdateSyncComponent,
  BtnDownloadComponent,
  BtnImportFileComponent,
  BtnDownloadTemplateComponent,
  BtnChooseFileExcelComponent,
  BtnPrintComponent,
  BtnAddRowComponent,
  BtnDeleteRowComponent,
  IconInfoComponent,
  IconCommentComponent,
  IconEditComponent,
  IconCheckComponent,
  IconDeleteComponent,
  IconResetPassComponent,
  SortDirective,
  IconApproveComponent,
  IconDeclineComponent,
  IconShareComponent,
  IconReplyComponent,
  IconResetPassComponent,
  SendComponent,
  DialogConfirmComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  ConvertDatePipe,
  NumberWithDotPipe,
  CustomTextPipe
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...MAT_MODULES, CommonModule],
  exports: [
    ...BASE_MODULES,
    ...NB_MODULES,
    ...MAT_MODULES,
    ...PIPES,
    ...COMPONENTS,
  ],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
