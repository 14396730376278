import { FormControl, FormGroup, NgForm } from "@angular/forms";
import {
  AttFileattachmentEntity,
  FileParameter,
  PageModel,
} from "./../../../@core/data/app-home.service";
import { Component, Injectable, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
// import {
//   FileParameter,
//   UnitSelectModel,
// } from "../../../@core/data/app-qtht.service";
import {
  PAGE_SIZE_CUSTOM,
  SUCCESS_RESPONE,
  TYPE_MENU,
  TYPES_FILE_ACCEPT,
} from "../../../@core/customs/constants";
import { HoidapService } from "./web-hoidap.service";
import { WebHoidapchitietComponent } from "../web-hoidapchitiet/web-hoidapchitiet.component";

import {
  LinkService,
  ToolbarService,
} from "@syncfusion/ej2-angular-richtexteditor";
import { CommonService } from "../../../@core/customs/common.service";
// import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: "ngx-web-hoidap",
  templateUrl: "./web-hoidap.component.html",
  styleUrls: ["./web-hoidap.component.scss"],
})
export class WebHoidapComponent implements OnInit {
  private hostUrl: string = environment.APP_HOME_URL;

  public tools: object = {
    type: "MultiRow",
    items: [
      "Bold",
      "Italic",
      "Underline",

      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "SourceCode",
      "|",
      "Undo",
      "Redo",
    ],
  };

  public insertImageSettings: Object = {
    saveUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Save",
    removeUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Remove",
  };
  token: string | undefined;
  idloaibaiviet: any = "0";
  id: any = 0;
  unit: any = "0";
  basePath = environment.APP_HOME_PATH;
  constructor(
    private service: HoidapService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private dialog: MatDialog,
    private router: Router,

  ) {
    this.token = undefined;
  }
  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    console.debug(`Token [${this.token}] generated`);
  }

  ngOnInit(): void {
    this.loadData_baitin_khac();
    this.getUnit();
    this.getallUnit();
    this.GetTrangthai();
    this.getsobannganh();
    this.gethuyenthanhthi();
    this.getdonvikhac();
  }
  currentDate = new Date();
  LstbaivietCT: any = {
    data: [],
  };
  ListTrangthai: any = [];
  // lstUnit: UnitSelectModel[];
  lstUnitAll: any[];
  ListSoBanNganh: any[] = [];
  ListHuyenthanhthi: any[] = [];
  ListDonvikhac: any[] = [];
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: 5,
    condition: null,
    sortColumn: "0",
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: "VN",
  });
  chitietbaitin: any = {
    data: [],
  };
  disableNavbar: boolean = false;
  deo: FormGroup = new FormGroup({
    noidung: new FormControl(null),
  });
  listTypeFileAccept: any[] = TYPES_FILE_ACCEPT;

  selectedFiles: FileParameter[] = [];
  // target: HoidapModel = new HoidapModel();
  target: any;

  countSizeFiles: number = 0;
  chooseFile(files: any[]) {
    if (files.length === 0) {
      return;
    }
    this.countSizeFiles = 0;
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (
        files[i].size > 20000000 ||
        !this.listTypeFileAccept.includes(files[i].type)
      ) {
        this.commonService.toastrWarning("File đính kèm không hợp lệ");
        return;
      }
      this.countSizeFiles += files[i].size;
      const fileParameter: FileParameter = {
        data: files[i],
        fileName: files[i]["name"],
      };
      this.selectedFiles.push(fileParameter);
    }
    if (this.countSizeFiles > 20000000) {
      this.commonService.toastrWarning("File đính kèm quá dung lượng");
      1;
      this.selectedFiles = [];
      return;
    }
  }
  deleteFile(file: any) {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) this.selectedFiles.splice(index, 1);
  }

  checkcaptcha: boolean = true;
  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.checkcaptcha = false;
    }
  }

  create() {
    // console.debug(`Token [${this.token}] generated`);
    // if (confirm("Bạn muốn gửi câu hỏi ?")) {
    //   const listFile = this.selectedFiles;
    //   if (listFile.length > 0) {
    //     this.commonService.uploadFile("Hoi_dap", listFile).subscribe((res) => {
    //       if (res.code === SUCCESS_RESPONE) {
            // this.target.lstFileAttachment = res.data.map((file) => {
            //   return new AttFileattachmentEntity({
            //     fileid: file.id,
            //     filename: file.fileName,
            //     type: TYPE_MENU.HOIDAP,
            //   });
    //         // });
    //         this.save();
    //       }
    //     });
    //   } else {
    //     this.save();
    //   }
    // }
  }
  save() {
    // this.target.noiDung = this.deo.controls["noidung"].value;
    // this.target.timePhanHoi = null;
    // this.target.fileDinhKem = "";
    // this.target.time = this.currentDate;
    // this.target.trangThai = 0;
    // this.target.unitCode = null;
    // this.target.phanHoi = null;
    // this.target.matrangthai = "1";
    // this.target.loaihoidap = 0;
    // this.service.CreateHoidap(this.target).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     alert("Bạn đã gửi góp ý thành công, xin cảm ơn!");
    //     window.location.reload();
    //   }
    // });
  }

  changeDisableNavbar() {
    this.disableNavbar = !this.disableNavbar;
  }
  GetTrangthai() {
    // this.service.GetLsTrangthai().subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.ListTrangthai = result.data;
    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }
  getTenUnit(value: string) {
    if (value === null) return null;
    const name = this.lstUnitAll.find((x) => x.unitcode === value)?.tendonvi;
    return name;
  }
  getTenTrangthai(value: string) {
    if (value === null) return null;
    const name = this.ListTrangthai.find(
      (x) => x.matrangthai === value
    )?.tentrangthai;
    return name;
  }

  loadData_baitin_khac() {
    // this.service.pagingcustomHoidap(this.searchModel).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.LstbaivietCT.data = res.data;
    //   }
    // });
  }

  getUnit() {
    // this.service.Getsysunitlist().subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.lstUnit = result.data;
    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }
  getallUnit() {
    // this.service.Getsysunitsellectall().subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.lstUnitAll = result.data;
    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }
  getsobannganh() {
    const maCha = '03';
    // this.service.getDonvibymacha(maCha).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.ListSoBanNganh = res.data;
    //   }
    // });
  }
  gethuyenthanhthi() {
    const maCha = '02';
    // this.service.getDonvibymacha(maCha).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.ListHuyenthanhthi = res.data;
    //   }
    // });
  }
  getdonvikhac() {
    const maCha = '04';
    // this.service.getDonvibymacha(maCha).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.ListDonvikhac = res.data;
    //   }
    // });
  }
  loadData_by_id() {
    // this.service.getbyid(this.id).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.chitietbaitin = res.data;
    //   }
    // });
  }
  router1(unit: string) {
    this.router.navigate(["web-home/hoidapchitiet/" + unit]);
  }

  clickloadtintuc(id: number, loaihd: number) {
    this.router
      .navigate(["web-home/hoidapphanhoi/" + id + "/" + loaihd])
      .then(() => {
        this.id = id;
        this.loadData_by_id();
      });
  }
}
