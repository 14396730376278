import { Component, Input } from '@angular/core';

@Component({
    selector: 'ngx-icon-comment',
    templateUrl: './icon-comment.component.html',
    styleUrls: ['./icon-comment.component.scss']
})
export class IconCommentComponent {
    @Input() soTB: number;
    constructor() {
    }
}
