<div class="container-fluid">
    <div class="container">
      <div class="row gioi_thieu">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 p-0 gioi_thieu_breadcrumb">
          <div class="nomal-title"> {{target.trichyeu}}</div>
        </div>
      </div>
      <hr>
      <div class="row" style="margin-bottom: 100px;margin-top: 10px;">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 p-0">
            <table class="table table-bordered table-hover" id="tableDetail">
              <tbody>
                <tr>
                    <td class="text-left"><strong>Số ký hiệu</strong></td>
                    <td>{{ target.sohieu }}</td>

                    <td class="text-left"><strong>Cơ quan ban hành</strong></td>
                    <td>{{ target.coquanbanhanh }}</td>
                </tr>

                <tr>
                    <td class="text-left"><strong>Lĩnh vực</strong></td>
                    <td>{{ tenlinhvuc }}</td>

                    <td class="text-left"><strong>Tình trạng hiệu lực</strong></td>
                    {{ target.hieuluc === 1 ? 'Có hiệu lực' : target.hieuluc === 0 ? 'Chưa có hiệu lực' : target.hieuluc === 2 ? 'Hết hiệu lực' : '' }}
                </tr>
                <tr>
                    <td class="text-left"><strong>Hình thức văn bản</strong></td>
                    <td>{{ tenhinhthuc }}</td>

                    <td class="text-left"><strong>Ngày ban hành</strong></td>
                    <td>{{ target.ngaybanhanh | date: 'dd/MM/yyyy' }}</td>
                </tr>
                <tr>
                    <td class="text-left"><strong>Người ký</strong></td>
                    <td>{{ target.nguoiky }}</td>

                    <td class="text-left"><strong>Ngày hiệu lực</strong></td>
                    <td>{{ target.ngayhieuluc | date: 'dd/MM/yyyy' }}</td>
                </tr>
                <tr>
                    <td class="text-left"><strong>Chức vụ</strong></td>
                    <td>{{ target.chucvu }}</td>

                    <td class="text-left"><strong>Ngày hết hiệu lực</strong></td>
                    <td>{{ target.ngayhethieuluc | date: 'dd/MM/yyyy' }}</td>
                </tr>
                <tr>
                    <td class="text-left"><strong>File đính kèm</strong></td>
                    <td colspan="3"></td>
                    <!-- <td (click)="downloadFile(target.fileId)">{{ target.filename }}</td> -->
                </tr>
                <tr>
                    <td class="text-left" style="vertical-align: top;"><strong>Nội dung</strong></td>
                    <td colspan="3" [innerHTML]="target.noidung"></td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>