import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonService } from '../../../@core/customs/common.service';
import { PAGE_SIZE_CUSTOM, SUCCESS_RESPONE, TYPE_VB } from '../../../@core/customs/constants';
import { environment } from '../../../../environments/environment';
import { title } from 'process';
import { PageModel, QldtApiService } from '../../../@core/data/app-home.service';
import { Console } from 'console';

@Component({
  selector: 'ngx-web-vanban',
  templateUrl: './web-vanban.component.html',
  styleUrls: ['./web-vanban.component.scss'],
})
export class WebVanbanComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: QldtApiService,
    private dialog: MatDialog,
    private router: Router
  ) {

  }
  basePath = environment.APP_HOME_PATH;
   HOST = environment.PATH_IMAGE;
   LstVanban: any = {
    data: [],
  };
  currentDate = new Date();
  title: string;
  // pageModel: VanBanModel = new VanBanModel()
  searchModel: PageModel = new PageModel({
    search: '',
    currentPage: 1,
    pageSize: 10,
    status: 1
  });
  lstLVVB: any[] = [];
  lstLVB: any[] = [];
  ngOnInit(): void {
    this.loadData();
    this.loadLinhvucbytudien();
    this.loadLoaiVBbytudien()
  }

  loadData() {
    this.service.qldtAdminApiV1DmVanBanPaging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) { 
        this.LstVanban = res.data;
      }
    });
  }
  ma: any;
  loadLinhvucbytudien(){
    this.service.appWebHomeApiV1WebHomeGetDmTuDienByType(TYPE_VB.LVVB, this.ma).subscribe((res) =>{
      if (res.code === SUCCESS_RESPONE) {
        this.lstLVVB = res.data;
        console.log(this.lstLVVB);
      }
    });
  }
  
  loadLoaiVBbytudien(){
    this.service.appWebHomeApiV1WebHomeGetDmTuDienByType(TYPE_VB.LVB, this.ma).subscribe((res) =>{
      if (res.code === SUCCESS_RESPONE) {
        this.lstLVB = res.data;
      }
    });
  }
  clickloadvanban(id: number) {
    this.router.navigate(["web-home/chitietvanban/" + id]);
  }
  searchInput: string = '';
  selectedCategory: string = '';
  selectedLoaiVanban: string = '';
  search(): void {
    this.searchModel.search = this.searchInput;
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  onCategoryChange() {
    this.search();
  }
}
