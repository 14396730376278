<div _ngcontent-lna-c1068="" class="container">
  <div class="ms-webpart-zone ms-fullWidth">
    <div id="MSOZoneCell_WebPartctl00_ctl36_g_58660116_4c49_4ea6_a98b_f80c452712bf" class="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical-inline-table ms-webpart-cell-vertical ms-fullWidth ">
      <div class="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
        <div webpartid="58660116-4c49-4ea6-a98b-f80c452712bf" haspers="false" id="WebPartctl00_ctl36_g_58660116_4c49_4ea6_a98b_f80c452712bf" width="100%" class="ms-WPBody noindex " allowdelete="false" allowexport="false" style=""><div id="ctl00_ctl36_g_58660116_4c49_4ea6_a98b_f80c452712bf">
          
  
  
  <script type="text/javascript" src="/_layouts/15/assets/global/plugins/angularjs/angular-sanitize.min.js"></script>
  <script type="text/javascript" src="/_layouts/15/assets/global/plugins/angularjs/angular-touch.min.js"></script>
  <script type="text/javascript" src="/_layouts/15/assets/global/plugins/angularjs/angular.min.js"></script><script type="text/javascript" src="/_layouts/15/assets/global/plugins/angularjs/plugins/ui-bootstrap-tpls-1.3.3.min.js"></script>
  
  <script type="text/javascript" src="/_layouts/15/BTS.SP.IFT/ChuyenDoi/controller.js"></script>
  <div ng-app="appChuyenDoi" id="contentMain" ng-controller="ctrChuyenDoi as vm" class="ng-scope">
      <!-- ngInclude: '/_layouts/15/BTS.SP.IFT/ChuyenDoi/index.html' --><div ng-include="'/_layouts/15/BTS.SP.IFT/ChuyenDoi/index.html'" class="ng-scope"><div class="container ng-scope">
      <div class="row">
          <h3>Chuyển đổi thông tin </h3>
      </div>
      <div class="row">
          <div class="col-md-9">
              <select ng-model="vm.fitlerBy" class="form-control ng-pristine ng-valid ng-touched" ng-change="filter()"><option value="? undefined:undefined ?"></option>
                  <!-- ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="1" class="ng-binding ng-scope">Lớp học/Khóa học</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="2" class="ng-binding ng-scope">Lịch giảng</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="3" class="ng-binding ng-scope">Danh sách học viên</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="4" class="ng-binding ng-scope">Chứng chỉ, chứng nhận</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="5" class="ng-binding ng-scope">Tài liệu giáo án</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="6" class="ng-binding ng-scope">Giáo trình bải giảng trực tuyến</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="7" class="ng-binding ng-scope">Video bài giảng trực tuyến</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="8" class="ng-binding ng-scope">Điểm học viên</option><!-- end ngRepeat: item in listFilter --><option ng-repeat="item in listFilter" value="9" class="ng-binding ng-scope">Tài liệu đào tạo </option><!-- end ngRepeat: item in listFilter -->
              </select>
          </div>
      </div>
      <hr>
      <div class="row">
          <h4>Kết quả</h4>
      </div>
      <div class="row">
          <div class="container">
              <!-- ngIf: vm.fitlerBy==1 -->
              <!-- ngIf: vm.fitlerBy==2 -->
              <!-- ngIf: vm.fitlerBy==3 -->
              <!-- ngIf: vm.fitlerBy==4 -->
              <!-- ngIf: vm.fitlerBy==5 -->
              <!-- ngIf: vm.fitlerBy==6 -->
              <!-- ngIf: vm.fitlerBy==7 -->
              <!-- ngIf: vm.fitlerBy==8 -->
              <!-- ngIf: vm.fitlerBy==9 -->
              <div style="text-align: right;">
                  <ul class="pagination-sm pagination ng-isolate-scope ng-valid" ng-change="vm.pageChanged()" items-per-page="vm.pageSize" boundary-links="true" max-size="vm.maxSize" total-items="vm.totalItems" ng-model="vm.currentPage" previous-text="‹" next-text="›" first-text="«" last-text="»">
    <!-- ngIf: ::boundaryLinks --><li ng-if="::boundaryLinks" ng-class="{disabled: noPrevious()||ngDisabled}" class="pagination-first ng-scope disabled"><a href="" ng-click="selectPage(1, $event)" class="ng-binding">«</a></li><!-- end ngIf: ::boundaryLinks -->
    <!-- ngIf: ::directionLinks --><li ng-if="::directionLinks" ng-class="{disabled: noPrevious()||ngDisabled}" class="pagination-prev ng-scope disabled"><a href="" ng-click="selectPage(page - 1, $event)" class="ng-binding">‹</a></li><!-- end ngIf: ::directionLinks -->
    <!-- ngRepeat: page in pages track by $index --><li ng-repeat="page in pages track by $index" ng-class="{active: page.active,disabled: ngDisabled&amp;&amp;!page.active}" class="pagination-page ng-scope active"><a href="" ng-click="selectPage(page.number, $event)" class="ng-binding">1</a></li><!-- end ngRepeat: page in pages track by $index -->
    <!-- ngIf: ::directionLinks --><li ng-if="::directionLinks" ng-class="{disabled: noNext()||ngDisabled}" class="pagination-next ng-scope disabled"><a href="" ng-click="selectPage(page + 1, $event)" class="ng-binding">›</a></li><!-- end ngIf: ::directionLinks -->
    <!-- ngIf: ::boundaryLinks --><li ng-if="::boundaryLinks" ng-class="{disabled: noNext()||ngDisabled}" class="pagination-last ng-scope disabled"><a href="" ng-click="selectPage(totalPages, $event)" class="ng-binding">»</a></li><!-- end ngIf: ::boundaryLinks -->
  </ul>
              </div>
          </div>
      </div>
  </div></div>
  </div>
  
        </div><div class="ms-clear"></div></div>
      </div>
    </div>
  </div>
</div>

<button (click)="topFunction1()" id="myBtn" title="Go to top" style="opacity: 0.5">
  <img class="rounded justify-content-center" src="{{ basePath }}/assets/images/banner/uparrow.png"
    alt="IMG" style="width: 30px" />
</button>

