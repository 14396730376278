import {
  PageModel,
  TintucskvmApiResponse,
  TintucVmIEnumerablePageModelViewApiResponse,
  ObjectIEnumerableApiResponse,
  QldtApiService,

  // DmLienheEntity,
  // DmLienheEntityApiResponse,
  // LienheModelIEnumerablePageModelViewApiResponse,
  // LienheModelApiResponse,
  // LienheModel,

 
} from './../../../@core/data/app-home.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class LienheService {
  constructor(
    private qldtApiService: QldtApiService,
  ) {}

  // pagingLienHe(
  //   body: PageModel
  // ): Observable<LienheModelIEnumerablePageModelViewApiResponse> {
  //   return this.api.appAdminApiDmLienHePagingLienHe(body);
  // }

  // CreateLienHe(entity: LienheModel): Observable<LienheModelApiResponse> {
  //   return this.api.appWebHomeApiWebHomeCreateLienHe(entity);
  // }

  // getbyid(id: any): Observable<DmLienheEntityApiResponse> {
  //   return this.api.appAdminApiDmLienHeSelectOne(id);
  // }
  // getLienhe(): Observable<ObjectIEnumerableApiResponse> {
  //   return this.api.appAdminApiDmLienHeSelectAll();
  // }


  // DmlienheCreate(
  //   body: LienheModel
  //   ): Observable<LienheModelApiResponse> {
  //     return this.api.appWebHomeApiWebHomeCreateLienhe(body);
  //   }
}
