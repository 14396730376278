import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonService } from '../../../@core/customs/common.service';
import { TYPE_ANH_VIDEO, PAGE_SIZE_CUSTOM, SUCCESS_RESPONE, TYPE_VB } from '../../../@core/customs/constants';
import { environment } from '../../../../environments/environment';
import { title } from 'process';
import { PageModel, PageModelLienKet, QldtApiService } from '../../../@core/data/app-home.service';
import { Console } from 'console';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ngx-web-page-video',
  templateUrl: './web-page-video.component.html',
  styleUrls: ['./web-page-video.component.scss'],
})
export class WebVideoComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: QldtApiService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {

  }
  basePath = environment.APP_HOME_PATH;
  HOST = environment.PATH_IMAGE;
  LstVideo: any = {
    data: [],
  };
  currentDate = new Date();
  title: string;
  searchModel: PageModelLienKet = new PageModelLienKet({
    search: '',
    currentPage: 1,
    pageSize: 12,
    status: 1,
    type: null,
  });
  type: string = '';

  ngOnInit(): void {
    this.loadVideo();
  }


  convertToEmbedLink(link: string): string {
    return link.replace('watch?v=', 'embed/');
  }
  getSafeUrl(link: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }
  loadVideo() {
    this.searchModel.type = TYPE_ANH_VIDEO.VIDEO;
    this.service.qldtAdminApiV1DmAnhVideoPagingCustom(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) { 
        this.LstVideo = res.data.data;
        // .map(video => {
        //   const embedLink = this.convertToEmbedLink(video.link); // Chuyển đổi link nhúng
        //   return { link: this.sanitizer.bypassSecurityTrustResourceUrl(embedLink) };
        // });
      }
    });
  }

}
