<div class="container-fluid">
  <div class="container">
    <div class="row">
      <h2 class="head-danh-muc">
        <div class="field__item">Video</div>
      </h2>
      <hr style="border: 3px solid #006cff" />
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-4 post-item" *ngFor="let item of LstVideo" style="padding-right: 5px;">
        <div>
          <a target="_blank" href="{{ item.link }}">
            <div style="border: none;">
              <!-- <iframe width="100%" height="300px" class="d-block" [src]="item.link" 
            frameborder="0" allowfullscreen> <h6>{{ item.name }}</h6></iframe> -->
              <!-- <video width="100%" controls>
                <source [src]="getSafeUrl(item.link)" />
              </video> -->
              <img class="clearfix rounded" src="{{HOST + item.thumb}}" />
              <div>
                <h6>{{ item.name }}</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div style="justify-content:center; display: flex;">
      <ngx-pagination
      (pageChange)="searchModel.currentPage = $event; loadVideo()"
      (pageSize)="searchModel.pageSize = $event; loadVideo()"
      [currentPage]="searchModel.currentPage"
      [totalPages]="LstVideo.totalPage" >
    </ngx-pagination>
    </div>
  </div>
</div>