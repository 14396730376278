import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../../../@core/customs/common.service';
import { environment } from '../../../../environments/environment';
import { AttFileattachmentEntity, PageModel } from '../../../@core/data/app-home.service';
import { PAGE_SIZE_CUSTOM, SUCCESS_RESPONE } from '../../../@core/customs/constants';
import { HoidapService } from '../web-hoidap/web-hoidap.service';
import { WebHoidapchitietComponent } from '../web-hoidapchitiet/web-hoidapchitiet.component';
@Component({
  selector: 'ngx-web-hoidapphanhoi',
  templateUrl: './web-hoidapphanhoi.component.html',
  styleUrls: ['./web-hoidapphanhoi.component.scss'],
})
export class WebHoidapphanhoiComponent implements OnInit {
  id: any = 0;
  idloaibaiviet: any = '0';
  loaihd: string;
  constructor(
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private service: HoidapService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
      this.idloaibaiviet = params['idloaibaiviet'];
      this.loaihd = params['loaihd'];
    });
  }
  basePath = environment.APP_HOME_PATH;
  LstbaivietCT: any = {
    data: [],
  };
  ListTrangthai: any = [];
   HOST = environment.PATH_IMAGE;
   HREF = "https://demo-portal.phutho.gov.vn/File/2022/Hoi_dap/"
  // target: TintucSukienEntity = new TintucSukienEntity();
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: 'VN',
  });
  // lstUnit: UnitSelectModel[];
  chitietbaitin: any = {
    data: [],
  };
  theHtmlStringNoiung: any;
  lstUnitAll: any[];
  ListSoBanNganh: any[] = [];
  ListHuyenthanhthi: any[] = [];
  ListDonvikhac: any[] = [];
  ngOnInit(): void {
    this.loadData_by_id();
    this.loadData_baitin_khac();
    this.getUnit();
    this.getallUnit();
    this.GetTrangthai();
    this.getsobannganh();
    this.gethuyenthanhthi();
    this.getdonvikhac();
  }

  getsobannganh() {
    const maCha = '03';
    // this.service.getDonvibymacha(maCha).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.ListSoBanNganh = res.data;
    //   }
    // });
  }
  gethuyenthanhthi() {
    const maCha = '02';
    // this.service.getDonvibymacha(maCha).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.ListHuyenthanhthi = res.data;
    //   }
    // });
  }
  getdonvikhac() {
    const maCha = '04';
    // this.service.getDonvibymacha(maCha).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.ListDonvikhac = res.data;
    //   }
    // });
  }
  selectedFiles: AttFileattachmentEntity[] = [];
  loadData_by_id() {
    // this.service.getbyid(this.id).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.chitietbaitin = res.data;
    //     this.theHtmlStringNoiung = this.chitietbaitin.phanhoi;
    //     this.service.DmHoidapGetFile(res.data.id).subscribe((dataFile) => {
    //       if (dataFile.code === SUCCESS_RESPONE) {
    //         this.selectedFiles = dataFile.data;
    //       } else {
    //         this.commonService.toastrDanger(dataFile.message);
    //       }
    //     });
    //   }
    // });
  }
  router1(unit: string) {
    this.router.navigate(["web-home/hoidapchitiet/" + unit +'/'+this.loaihd])
  }

    clickloadtintuc(id: string) {
      this.router.navigate(["web-home/hoidapchitiet/" + id]).then(() => {
        this.id = id;
        this.loadData_by_id();
      });
    }
  getUnit() {
    // this.service.Getsysunitlist().subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.lstUnit = result.data;
    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }
  getTenUnit(value: string) {
    // if (value === null || value === undefined || value === '') return null;
    // const name = this.lstUnit.find((x) => x.unitcode === value).unitname
    //   ? this.lstUnit.find((x) => x.unitcode === value).unitname
    //   : null;
    // return name;
    
  }
  getTenTrangthai(value: string) {
    if (value === null || value === undefined || value === '') return null;
    const name = this.ListTrangthai.find((x) => x.matrangthai === value).tentrangthai
      ? this.ListTrangthai.find((x) => x.matrangthai === value).tentrangthai
      : null;
    return name;
   
  }
  GetTrangthai() {
    // this.service.GetLsTrangthai().subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.ListTrangthai = result.data;
    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }

  loadData_baitin_khac() {
    this.searchModel.condition = this.idloaibaiviet;
    // this.service.pagingHoidap(this.searchModel).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.LstbaivietCT = res.data.data.slice(0, 10);
    //   }
    // });
  }
  getallUnit() {
    // this.service.Getsysunitsellectall().subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.lstUnitAll = result.data;

    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}
