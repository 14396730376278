import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebHoidapchitietComponent } from './web-hoidapchitiet.component';

export const routes: Routes = [
  {
    path: ':unitcode/:loaihd',
    component: WebHoidapchitietComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebHoidapchitietRoutingModule {
}
