<div class="container" style="margin-top: 10px;">
  <div class="row vanban_search">
    <div class="col-md-6 col-xs-12">
      <div class="form-group">
        <label class="control-label">Tìm kiếm</label>
        <div class="form-group">
          <input autocomplete="off" type="text" class="form-control" name="timkiem" placeholder="Nhập từ khóa  ..." />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-xs-12 text-center">
      <button style="margin-top: 10px;" type="button" class="btn btn-primary justify-content-center" (click)="loadData()">
        Tìm kiếm
      </button>
    </div>
  </div>

  <div class="row" style="margin-top: 10px;">
    <div class="col-md-12" style="padding-right: 20px;">
      <div class="row control-label">
        <div class="col-12" style="overflow: auto;">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px;">STT</th>
                <th class="text-center" style="min-width: 100px;">Mã học viên</th>
                <th class="text-center" style="min-width: 100px;">Điểm tổng kết</th>
                <th class="text-center" style="min-width: 100px;">Tổng kết</th>
              </tr>
            </thead>
            <tbody>
              <!-- Nội dung bảng -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <button (click)="topFunction1()" id="myBtn" title="Go to top">
    <img class="rounded justify-content-center" src="{{ basePath }}/assets/images/banner/uparrow.png" alt="IMG" />
  </button>
</div>

