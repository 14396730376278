<div class="container-fluid">
    <div class="container">
        <div class="row hd1">
            <div class="col-md-3 mt-4">
                <h4 style="color: #006cff">LOẠI TIN</h4>
                <ul class="normal-list treeview">
                    <li><a href="#">Tin tức sự kiện</a></li>
                    <li class="contains-items" (click)="toggleMenu($event)">Hoạt động đào tạo
                        <ul class="normal-list">
                            <li><a href="#">Các chương trình đào tạo</a></li>
                            <li><a href="#">Kế hoạch đào tạo bồi dưỡng</a></li>
                            <li><a href="#">Tổ chức đào tạo bồi dưỡng</a></li>
                            <li><a href="#">Hoạt động đào tạo bồi dưỡng</a></li>
                        </ul>
                    </li>
                    <li class="contains-items" (click)="toggleMenu($event)">Nghiên cứu khoa học
                        <ul class="normal-list">
                            <li><a href="#">Hội thảo khoa học</a></li>
                            <li><a href="#">Các đề tài nghiên cứu khoa học cấp Bộ</a></li>
                            <li><a href="#">Các chương trình nghiên cứu của Trường</a></li>
                            <li><a href="#">Chương trình nghiên cứu cấp nhà nước</a></li>
                        </ul>
                    </li>
                    <li class="contains-items" (click)="toggleMenu($event)">Hợp tác quốc tế
                        <ul class="normal-list">
                            <li><a href="#">Chương trình đào tạo nước ngoài</a></li>
                            <li><a href="#">Chương trình đào tạo trong nước</a></li>
                            <li><a href="#">Hội thảo quốc tế</a></li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="col-md-9 my-4" id="print-section">
                <div class="row">
                    <h3>{{ target?.tieude }}</h3>
                    <span>
                        {{ target?.ngayxuatban | date : "dd/MM/yyyy" }}
                    </span>
                </div>
                <div class="blocknoidung" [innerHTML]="sanitizer.bypassSecurityTrustHtml(target?.noidung)"></div>

                <div *ngIf="listtinkhac.length">
                    <h4 class="border-color d-inline-block">Tin khác</h4>
                    <div class="news-block" *ngFor="let item of listtinkhac">
                        <a href="javascript:void(0)" (click)="tinTucGioiThieu(item?.id)">{{ item?.tieude }}</a>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <button (click)="topFunction1()" id="myBtn" title="Go to top" style="opacity: 0.5">
        <img class="rounded justify-content-center" src="{{ basePath }}/assets/images/banner/uparrow.png"
          alt="IMG" style="width: 30px" />
      </button>
</div>