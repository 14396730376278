import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCardModule,
  NbDialogModule,
  NbDirectionality,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbTreeGridDataService,
  NbTreeGridDataSourceBuilder,
  NbTreeGridFilterService,
  NbTreeGridService,
  NbTreeGridSortService,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { EditorModule } from '@progress/kendo-angular-editor';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FooterComponent } from '../../@theme/layouts/home-layouts/footer/footer.component';
import { HeaderComponent } from '../../@theme/layouts/home-layouts/header/header.component';
import { HomeLayoutsComponent } from './../../@theme/layouts/home-layouts/home-layouts.component';
import { WebHomeRoutingModule } from './web-home-routing.module';
import { WebTimkiemComponent } from './web-timkiem/web-timkiem.component';
import { WebXemthemtintucComponent } from './web-xemthemtintuc/web-xemthemtintuc.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AccumulationChartAllModule, ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaLoaderService, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NgChartsModule } from 'ng2-charts';
import { NgxCurrencyModule } from 'ngx-currency';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxPrintModule } from 'ngx-print';
import { SwiperModule } from "swiper/angular";
import { environment } from '../../../environments/environment';
import { CachedSrcDirective } from '../../@core/customs/cachedsrcdirective';
import { ThemeModule } from '../../@theme/theme.module';
import { BodyComponent } from './body/body.component';
import { WebVanbanComponent } from './web-vanban/web-vanban.component';
import { WebVanbanchitietComponent } from './web-vanbanchitiet/web-vanbanchitiet.component';
import { WebTracuudiemthiComponent } from './web-tracuudiemthi/web-tracuudiemthi.component';
import { WebVideoComponent } from './web-page-video/web-page-video.component';
import { WebAnhComponent } from './web-page-anh/web-page-anh.component';
@NgModule({
  declarations: [
    HomeLayoutsComponent,
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    WebXemthemtintucComponent,
    WebTimkiemComponent,
    CachedSrcDirective,
    WebVanbanComponent,
    WebVanbanchitietComponent,
    WebTracuudiemthiComponent,
    WebVideoComponent,
    WebAnhComponent
  ],
  imports: [
    NbCardModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    ThemeModule,
    WebHomeRoutingModule,
    NgbModule,
    IvyCarouselModule,
    TreeListModule,
    DialogsModule,
    EditorModule,
    NbDialogModule.forRoot(),
    NbCardModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    MatTabsModule,
    NbButtonModule,
    TreeViewModule,
    LayoutModule,
    NgxCurrencyModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgCircleProgressModule,
    NbAccordionModule,
    NgChartsModule,
    PDFExportModule,
    NgxPrintModule,
    OwlModule,
    SwiperModule,
    ChartAllModule,
    AccumulationChartAllModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    RecaptchaLoaderService,
    NbTreeGridDataSourceBuilder,
    NbTreeGridFilterService,
    NbTreeGridSortService,
    NbTreeGridService,
    NbTreeGridDataService,
    NbDirectionality,
  ],
})
export class WebHomeModule {}
