import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { CommonService } from "../../../../@core/customs/common.service";
import {
  PAGE_SIZE_CUSTOM,
  SUCCESS_RESPONE,
} from "../../../../@core/customs/constants";
import { PageModel } from "../../../../@core/data/app-home.service";
import { WebTimkiemComponent } from "../../../../modules/web-home/web-timkiem/web-timkiem.component";
import { WebxemthemService } from "./../../../../modules/web-home/web-xemthemtintuc/web-xemthemtintuc.service";

@Component({
  selector: "ngx-web-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private webxemthemService: WebxemthemService,
    private router: Router,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {}
  l: boolean = false;
  check: boolean = false;
  LstHeader: any[] = [];
  today = new Date();
  HOST = environment.PATH_IMAGE;
  ngOnInit(): void {
    sessionStorage.clear();
  }

  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: "VN",
    searchByNoiDung: null,
  });

  search1: string;
  basePath: string = environment.APP_HOME_PATH;

  search() {
    this.dialog
      .open(
        WebTimkiemComponent,
        this.commonService.configDialog("80%", {
          noidung: this.searchModel.searchByNoiDung,
        })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.loadData_header();
      });
  }

  loadData_header() {
    // this.searchModel.condition = "293";
    // this.webxemthemService.pagingCustom(this.searchModel).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.LstHeader = res.data.data;
    //   }
    // });
  }

  // Login():void {
  //   this.router.navigate(['web-admin/'])
  // }

  tinTucGioiThieu(loaibaiviet: string) {
    this.router.navigate([ "web-home/tintucgioithieu/" + loaibaiviet + '/0' ])
  }
  clickXemTin(loaibaiviet: string):void {
    this.router.navigate(['web-home/xemthemtintuc/' + loaibaiviet])
  }
}
