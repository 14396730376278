<div class="container-fluid" style="background-color: #024C6C;padding-top: 20px;">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 text-left">
        <p style="font-size: 16px; color:white !important;"><strong>Trường Bồi dưỡng cán bộ tài chính - Bộ Tài chính</strong></p>
        <p style="font-size: 15px; color:white !important;">
          <i class="fa-solid fa-location-dot"></i> 
          Số 291, Ngõ 343, phố Đội Cấn, phường Liễu Giai, Quận Ba Đình, TP Hà Nội
        </p>
        <p style="font-size: 15px; color:white !important;">
          <i class="fa-solid fa-phone"></i> 024.39716638
          <i class="fa-solid fa-fax ms-5"></i> 024.39716638
        </p>
        <p>
          <a href="mailto:makeinvietnam@mic.gov.vn" style="font-size: 15px; color: white !important; text-decoration: none;">
            <i class="fa-solid fa-envelope"></i> admin&#64;ift.edu.vn
          </a>
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 text-right">
        <a style="float: right; font-style: italic; color: white">
          <strong>Số lượt truy cập : </strong>
          <img src="https://hitwebcounter.com/counter/counter.php?page=15669323&style=0006&nbdigits=6&type=page&initCount=0" title="Counter Widget" Alt="Visit counter For Websites"   border="0" />
        </a>
      </div>
    </div>
  </div>
</div>