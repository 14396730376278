import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { pagerRefresh } from '@syncfusion/ej2-grids';

@Component({
  selector: 'ngx-home-layouts',
  templateUrl: './home-layouts.component.html',
  styleUrls: ['./home-layouts.component.scss']
})
export class HomeLayoutsComponent implements OnInit {

  constructor(
    private deviceService: DeviceDetectorService,
    private route: Router,

  ) { }

  ngOnInit(): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet() ) {
      this.route.navigateByUrl('/m-mobile');
    }
  }
}
