import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  { path: '', redirectTo: 'web-home', pathMatch: 'full' },
  // {
  //   path: '',
  //   loadChildren: () => import('../@theme/pages/pages.module')
  //     .then(m => m.PagesModule),
  // },
  {
    path: 'web-home',
    loadChildren: () => import('./web-home/web-home.module')
      .then(m => m.WebHomeModule),
  },


];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
