<div class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" style="padding: 15px;overflow: hidden">
        <div >
          <swiper [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="false"  [navigation]="true">
          <ng-container *ngFor="let item of lstAnhNoibat">
            <ng-template swiperSlide>
              <div class="card shadow p-3 mb-5 bg-white rounded ">
                <img class="img_top"
                  [src]="item.link.startsWith('http') ? item.link : HOST + item.link" 
                  alt="Card image cap">
                <a Class="title_img" href="#">{{ item.name }}</a>
              </div>
            </ng-template>
          </ng-container>
          </swiper>
        </div>
      </div>
    </div>

    <div class="divide"></div>

    <div class="row">
      <div class="col-2 text-center px-3 ps-2">
        <div class="menu-icon">
          <a href="#" [routerLink]="['/web-home/xemthemtintuc', '14']">
            <img src="{{ basePath }}/assets/images/a0.png" alt="" class="mt-2">
            <p>Lịch tuần</p>
          </a>
        </div>
      </div>
      <div class="col-2 text-center px-3">
        <div class="menu-icon">
          <a href="#" [routerLink]="['/web-home/vanban']">
            <img src="{{ basePath }}/assets/images/a2.png" alt="" class="mt-2">
            <p>Văn bản</p>
          </a>
        </div>
      </div>
      <div class="col-2 text-center px-3">
        <div class="menu-icon">
          <a href="#" [routerLink]="['/web-home/xemthemtintuc', '15']">
            <img src="{{ basePath }}/assets/images/a3.png" alt="" class="mt-2">
            <p>Điều hành nội bộ</p>
          </a>
        </div>
      </div>
      <div class="col-2 text-center px-3">
        <div class="menu-icon">
          <img src="{{ basePath }}/assets/images/a4.png" alt="" class="mt-2">
          <p>Đào tạo trực tuyến</p>
        </div>
      </div>
      <div class="col-2 text-center px-3">
        <div class="menu-icon">
          <a href="{{urlquantri}}" target="_blank">
            <img src="{{ basePath }}/assets/images/a4.png" alt="" class="mt-2">
            <p>Phần mềm QLĐT</p>
        </a>
        </div>
      </div>
      <div class="col-2 text-center px-3 pe-2">
        <div class="menu-icon">
          <a href="#" [routerLink]="['/web-home/xemthemtintuc', '16']">
            <img src="{{ basePath }}/assets/images/a5.png" alt="" class="mt-2">
            <p>Tuyển sinh</p>
          </a>
        </div>
      </div>
    </div>

    <div class="divide"></div>

    <div class="row">
      <div class="col-4 pe-5 mt-3" *ngFor="let item of listTitleNews">
        <h6 class="text-uppercase d-inline-block border-color">{{ item.value }}</h6>
        <div class="content-baiviet">
          <div class="news-block" *ngFor="let item2 of getList(item.id)">
            <a *ngIf="item.id === '00'" style="cursor: pointer;" (click)="clickloadvanban(item2.id)">
              {{ item2.trichyeu }}
            </a>
            <a *ngIf="item.id !== '00'" style="cursor: pointer;" (click)="clickloadtintuc(item2.id, item2.loaibaiviet)">
              {{ item2.tieude }}
            </a>
          </div>
        </div>
        <div class="text-end mt-2">
          <button *ngIf="item.id =='00'" (click)="clickThemVanBan()" class="btn btn-primary btn-sm text-end">Xem thêm</button>
          <button *ngIf="item.id !=='00'" (click)="clickThemTin(item.id)" class="btn btn-primary btn-sm text-end">Xem thêm</button>
        </div>
      </div>
    </div>

    <div class="divide"></div>

    <div class="">
      <h6 class="text-uppercase d-inline-block border-color">Các đơn vị</h6>
      <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button (click)="loadDonvi('01', 'tab-pane-1')" 
            class="nav-link" [class.active]="activeTab === 'tab-pane-1'" id="home-tab" data-bs-toggle="tab" type="button"
            role="tab" aria-controls="tab-pane-1" aria-selected="true">Phòng chức năng</button>
        </li>
        <li class="nav-item" role="presentation">
          <button (click)="loadDonvi('02', 'tab-pane-2')"
            class="nav-link" [class.active]="activeTab === 'tab-pane-2'" id="profile-tab" data-bs-toggle="tab" type="button"
            role="tab" aria-controls="tab-pane-2" aria-selected="false">Khoa</button>
        </li>
        <li class="nav-item" role="presentation">
          <button (click)="loadDonvi('03', 'tab-pane-3')" 
            class="nav-link" [class.active]="activeTab === 'tab-pane-3'" id="contact-tab" data-bs-toggle="tab" type="button"
            role="tab" aria-controls="tab-pane-3" aria-selected="false">Trung tâm đào tạo</button>
        </li>
      </ul>
    
      <div class="tab-content mt-2" id="myTabContent">
        <div *ngIf="activeTab === 'tab-pane-1'" class="text-center" id="tab-pane-1" role="tabpanel" tabindex="0">
          <div class="col-3 donviblock" *ngFor="let item of lstDonvi; trackBy: trackByFn">
            <a routerLink="{{ item.linkWeb }}">
              <img src="{{ HOST + item.img }}" alt="">
              <p class="text-light bg-dark text-center mb-0 p-1">{{ item.name }}</p>
            </a>
          </div>
        </div>
        <div *ngIf="activeTab === 'tab-pane-2'" class="text-center" id="tab-pane-2" role="tabpanel" tabindex="0">
          <div class="col-3 donviblock" *ngFor="let item of lstDonvi; trackBy: trackByFn">
            <a routerLink="{{ item.linkWeb }}">
              <img src="{{ HOST + item.img }}" alt="">
              <p class="text-light bg-dark text-center mb-0 p-1">{{ item.name }}</p>
            </a>
          </div>
        </div>
        <div *ngIf="activeTab === 'tab-pane-3'" class="text-center" id="tab-pane-3" role="tabpanel" tabindex="0">
          <div class="col-3 donviblock" *ngFor="let item of lstDonvi; trackBy: trackByFn">
            <a routerLink="{{ item.linkWeb }}">
              <img src="{{ HOST + item.img }}" alt="">
              <p class="text-light bg-dark text-center mb-0 p-1">{{ item.name }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="divide"></div>

    <div class="row">
      <div class="col-3 px-2">
        <div class="p-3 border">
          <div class="marquee-container-y">
            <div class="marquee-content-y" *ngFor="let item of lstAnh">
              <img class="mb-2 border" width="100%" src="{{ item.link }}"
                alt="">
            </div>
          </div>
          <div class="text-end mt-2">
            <a [routerLink]="['/web-home/anh']" class="xemthem">Xem thêm</a>
          </div>
        </div>
      </div>

      <div class="col-6 px-3">
        <div class="px-2 py-3 border">
          <h6 class="text-uppercase d-inline-block border-color">Video</h6>
          <iframe *ngFor="let i of lstVideoNoibat" width="100%" height="300px" class="d-block" [src]="i.link" 
            frameborder="0" allowfullscreen></iframe>
          <div class="news-block" *ngFor="let item of lstVideo">
            <a target="_blank" href="{{ item.link }}">{{item.name}}</a>
          </div>
          <div class="text-end mt-2">
            <a [routerLink]="['/web-home/video']" class="xemthem">Xem thêm</a>
          </div>
        </div>
      </div>

      <div class="col-3 px-2">
        <div class="border px-2 py-3">
          <h6 class="text-uppercase d-inline-block border-color">Liên kết nhanh</h6>
          <div class="news-block" *ngFor="let item of listLienKet">
            <a href="{{ item.linkWeb }}" target="_blank">{{ item.ten }}</a>
          </div>
        </div>
        <div class="border px-2 py-3 mt-2">
          <h6 class="text-uppercase d-inline-block border-color">Tỷ giá hối đoái</h6>
          <div class="tableoverflow">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th class="text-center" width="12%">Mã NT</th>
                  <th class="text-center" width="22%">Tên ngoại tệ</th>
                  <th class="text-center" width="22%">Mua tiền mặt</th>
                  <th class="text-center" width="22%">Mua chuyển khoản</th>
                  <th class="text-center" width="22%">Bán</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of listTyGiaHoiDoai">
                  <td>{{ item.mant }}</td>
                  <td>{{ item.tennt }}</td>
                  <td class="text-end">{{ item.muatm }}</td>
                  <td class="text-end">{{ item.muack }}</td>
                  <td class="text-end">{{ item.ban }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="divide"></div>

    <div class="mb-4">
      <h6 class="text-uppercase d-inline-block border-color">Đối tác</h6>
      <div class="marquee-container">
        <div class="marquee mt-2" >
          <a target="_blank" *ngFor="let item of listdoitac" href="{{ item.linkWeb }}" >  
            <img [src]="HOST + item.img" [alt]="item.ten">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>