import { Component, HostListener, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CommonService } from "../../../@core/customs/common.service";
import { environment } from "../../../../environments/environment";
import {
  PAGE_SIZE_CUSTOM,
  SUCCESS_RESPONE,
} from "../../../@core/customs/constants";
import { PageModel, QldtApiService } from "../../../@core/data/app-home.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "ngx-web-chuyendoiDL",
  templateUrl: "./web-chuyendoiDL.component.html",
  styleUrls: ["./web-chuyendoiDL.component.scss"],
})
export class WebChuyendoiDLComponent implements OnInit {
  searchModel: any;
  idluongsukien: any;

  constructor(
    private service: QldtApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
  ) {

  }
  basePath = environment.APP_HOME_PATH;

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
  
  }
  loadData_luongsukien() {
    this.searchModel.childType = this.idluongsukien;
    // this.service.pagingCustom(this.searchModel).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.Lstluongsk = res.data.data.slice(0, 10);
    //   }
    // });
  }
  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (
      document.body.scrollTop > 40 ||
      document.documentElement.scrollTop > 40
    ) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction1() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  toggleMenu(event: Event) {
    const element = event.currentTarget as HTMLElement;
    element.classList.toggle('open');
  }
}
