<div class="container-fluid">
    <div class="container">
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3 col-xs-3 col-lg-3 col-xl-3">
                <div class="form-group">
                    <select id="categorySelect" class="form-control">
                    <option value="a">-Chọn nhóm hình ảnh-</option>
                    <option *ngFor="let item of LstAnh" [value]="" >
                        {{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 text-center">
                <button type="button" class="btn btn-primary justify-content-center"
                (click)="loadAnh()">Chọn</button>
            </div>
        </div>
  
        <div class="row" style="margin-top: 20px;">
            <div class="col-lg-3 col-md-4 col-xs-6 post-item" *ngFor="let item of LstAnh" style="padding-right: 5px;">
                <div style="border: none;">
                    <a href="{{item.link.startsWith('http') ? item.link : HOST + item.link}}">
                        <img class="clearfix image" 
                            [src]="item.link.startsWith('http') ? item.link : HOST + item.link" 
                            alt =""/>
                        <div>
                            <h6>{{ item.name }}</h6>
                        </div>
                    </a>
                </div>
            </div>
        </div>
  
        <div style="justify-content:center; display: flex;">
            <ngx-pagination
                (pageChange)="searchModel.currentPage = $event; loadAnh()"
                (pageSize)="searchModel.pageSize = $event; loadAnh()"
                [currentPage]="searchModel.currentPage"
                [totalPages]="LstAnh.totalPage" >
                </ngx-pagination>
        </div>
    </div>
</div>