import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ChartAnnotationSettingsModel, ChartTheme, IAxisLabelRenderEventArgs, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { environment } from "../../../../environments/environment";
import { CommonService } from "../../../@core/customs/common.service";
import {
  PAGE_SIZE_CUSTOM,
  SUCCESS_RESPONE
} from "../../../@core/customs/constants";
import {
  DmLienketVm,
  Dmtygiahoidoai,
  Dmvanban,
  Noidungbaiviet,
  PageModel,
  PageModelLienKet,
  QldtApiService
} from "../../../@core/data/app-home.service";
import { WebxemthemService } from "../web-xemthemtintuc/web-xemthemtintuc.service";

import { Browser } from "@syncfusion/ej2-base";
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
  Virtual,
  Zoom,
} from 'swiper';
import { DomSanitizer } from "@angular/platform-browser";
import { forkJoin } from "rxjs";

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

@Component({
  selector: "ngx-web-body",
  templateUrl: "./body.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./body.component.scss"],
})
export class BodyComponent implements OnInit {
  deviceInfo = null;
  showScroll: boolean;
  showScrollHeight = 3000;
  hideScrollHeight = 100;
  constructor(
    private commonService: CommonService,
    private webxemthemService: WebxemthemService,
    private dialog: MatDialog,
    private router: Router,
    private qldtApiService: QldtApiService,
    private sanitizer: DomSanitizer
  ) { }
  basePath: string = environment.APP_HOME_PATH;
  urlquantri: string = environment.URL_QUANTRI;
  HOST = environment.PATH_IMAGE;
  listLienKet: DmLienketVm[] = [];
  listTyGiaHoiDoai: Dmtygiahoidoai[] = [];
  listTinTuc: Noidungbaiviet[] = [];
  listLichKhaiGiang: Noidungbaiviet[] = [];
  listThongBao: Noidungbaiviet[] = [];
  listNghienCuuKhoaHoc: Noidungbaiviet[] = [];
  listHopTacQuocTe: Noidungbaiviet[] = [];
  listVanBan: Dmvanban[] = [];
  Listbv: any[] = [];
  LstNoibat: any[] = [];
  ListTintuc_sukien: any[] = [];
  listdoitac: any[] = [];
  lstAnh: any[] = [];
  lstAnhNoibat: any[] = [];
  lstVideo: any[] = [];
  lstVideoNoibat: any[] = [];

  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition2: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: "VN",
    loaibv: null,
    formType: -1,
  });

  dataModel: PageModelLienKet = new PageModelLienKet({
    search: null,
    currentPage: 1,
    pageSize: 12,
    status: 1,
    type: null,
  })
  
  target: any = {
    data: [],
  };
  listTitleNews: any[] = [
    { id: '01', value: 'Tin tức' },
    { id: '07', value: 'Lịch khai giảng' },
    { id: '08', value: 'Thông báo' },
    { id: '00', value: 'Văn bản' },
    { id: '04', value: 'Nghiên cứu khoa học' },
    { id: '05', value: 'Hợp tác quốc tế' },
  ];

  Donvi: any[] = [
    { id: '01', value: "Phòng chức năng"},
    { id: '02', value: "Khoa"},
    { id: '03', value: "Trung tâm đào tạo"},
  ]
  lstDonvi: any = {
    data: [],
  };

  ngOnInit(): void {
    this.loadAnh(); 
    window.addEventListener('scroll', this.onScroll.bind(this));
    
  }
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll.bind(this));
  }

  dataLoaded = {
    sec: false,
  };

  onScroll(): void {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;
    const section1 = document.getElementById('section1')?.offsetTop ?? 0;
    if (scrollTop + viewportHeight > section1 && !this.dataLoaded.sec) {
      this.loadData();
      this.dataLoaded.sec = true;
    }
  }

  loadData() {
    this.loadTinTuc();
    this.loadLichKhaiGiang();
    this.loadThongBao();
    this.loadNghienCuuKhoaHoc();
    this.loadHopTacQuocTe();
    this.loadVanBan();
    this.getLienket();
    this.getTyGiaHoiDoai();
    this.getdoitac();
    this.loadDonvi('01', 'tab-pane-1');
    this.loadVideo();
  }

  getList(value) {
    if (value == '01') return this.listTinTuc;
    if (value == '07') return this.listLichKhaiGiang;
    if (value == '08') return this.listThongBao;
    if (value == '04') return this.listNghienCuuKhoaHoc;
    if (value == '05') return this.listHopTacQuocTe;
    if (value == '00') return this.listVanBan;
  }

  clickloadtintuc(id: number, loaibaiviet: string) {
    this.router.navigate(["web-home/chitiettintuc/" + id + "/" + loaibaiviet + "/" + '0',])
  }

  clickThemTin(id: number):void {
    this.router.navigate(["web-home/xemthemtintuc/" + id]);
  }

  clickThemVanBan():void {
    this.router.navigate(["web-home/vanban"]);
  }
    
  clickloadvanban(id: number) {
    this.router.navigate(["web-home/chitietvanban/" + id]);
  }

  loadTinTuc():void{
    this.searchModel.condition2 = "01";
    this.searchModel.status = 1;
    this.qldtApiService.appWebHomeApiV1WebHomePagingCustomXemThem(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.listTinTuc = res.data.data.slice(0,12);
      }
    });
  }
  
  loadLichKhaiGiang() {
    this.searchModel.condition2 = "07";
    this.searchModel.status = 1;
    this.qldtApiService.appWebHomeApiV1WebHomePagingCustomXemThem(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.listLichKhaiGiang = res.data.data.slice(0,12);
      }
    });
  }

  loadThongBao() {
    this.searchModel.condition2 = "08";
    this.searchModel.status = 1;
    this.qldtApiService.appWebHomeApiV1WebHomePagingCustomXemThem(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.listThongBao = res.data.data.slice(0,12);
      }
    });
  }

  loadNghienCuuKhoaHoc() {
    this.searchModel.condition2 = "04";
    this.searchModel.status = 1;
    this.qldtApiService.appWebHomeApiV1WebHomePagingCustomXemThem(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.listNghienCuuKhoaHoc = res.data.data.slice(0,12);
      }
    });
  }

  loadHopTacQuocTe() {
    this.searchModel.condition2 = "05";
    this.searchModel.status = 1;
    this.qldtApiService.appWebHomeApiV1WebHomePagingCustomXemThem(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.listHopTacQuocTe = res.data.data.slice(0,12);
      }
    });
  }

  loadAnh() {
    const dataModelAnhNoibat = Object.assign({}, this.dataModel, { type: 1 });
    
    const dataModelAnh = Object.assign({}, this.dataModel, { type: 0 });
  
    const anhNoibatObservable = this.qldtApiService.qldtAdminApiV1DmAnhVideoPagingCustom(dataModelAnhNoibat);
    const anhObservable = this.qldtApiService.qldtAdminApiV1DmAnhVideoPagingCustom(dataModelAnh);
  
    forkJoin([anhNoibatObservable, anhObservable]).subscribe(([resAnhNoibat, resAnh]) => {
      if (resAnhNoibat.code === SUCCESS_RESPONE) {
        this.lstAnhNoibat = resAnhNoibat.data.data;
      }
      if (resAnh.code === SUCCESS_RESPONE) {
        this.lstAnh = resAnh.data.data.slice(0, 10);
      }
    });
  }
  

  convertToEmbedLink(link: string): string {
    return link.replace('watch?v=', 'embed/');
  }

  loadVideo(){
    this.dataModel.type = 2; // ma video
    this.qldtApiService.qldtAdminApiV1DmAnhVideoPagingCustom(this.dataModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.lstVideoNoibat = res.data.data.slice(0, 1).map(video => {
          const embedLink = this.convertToEmbedLink(video.link); // Chuyển đổi link nhúng
          return { link: this.sanitizer.bypassSecurityTrustResourceUrl(embedLink) };
        });
        this.lstVideo = res.data.data.slice(1,6)
        console.log(this.lstVideoNoibat);
      }
    });
  }

  loadVanBan() {
    this.searchModel.status = 1;
    this.qldtApiService.qldtAdminApiV1DmVanBanPaging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) { 
        this.listVanBan = res.data.data;
      }
    });
  }

  activeTab: string = 'tab-pane-1';
  loadDonvi(ma: string,  tabId: string){
    this.activeTab = tabId;
    this.qldtApiService.appWebHomeApiV1WebHomeSelectDonViByMaCha(ma).subscribe(res => {
      if (res.code == SUCCESS_RESPONE) {
        this.lstDonvi = res.data;
      }
    })
  }

  trackByFn(index: number, item: any) {
    return item.id; 
  }

  getLienket() {
    const maCha = '333';
    this.qldtApiService.appWebHomeApiV1WebHomeSelectLienKetByMaCha(maCha).subscribe(res => {
      if (res.code == SUCCESS_RESPONE) {
        this.listLienKet = res.data;
      }
    })
  }

  getdoitac() {
    const maCha = '222';
    this.qldtApiService.appWebHomeApiV1WebHomeSelectLienKetByMaCha(maCha).subscribe(res => {
      if (res.code == SUCCESS_RESPONE) {
        this.listdoitac = res.data;
      }
    })
  }

  getTyGiaHoiDoai() {
    this.qldtApiService.appWebHomeApiV1WebHomeGetGiaHoiDoai().subscribe(res => {
      if (res.code == SUCCESS_RESPONE) {
        this.listTyGiaHoiDoai = res.data;
      }
    })
  }
}
