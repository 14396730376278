import { dataCsvIcon } from '@progress/kendo-svg-icons';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../@core/customs/common.service';
import { environment } from '../../../../environments/environment';
import { PAGE_SIZE_CUSTOM, SUCCESS_RESPONE } from '../../../@core/customs/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { Noidungbaiviet, PageModel, QldtApiService } from '../../../@core/data/app-home.service';

@Component({
  selector: 'ngx-web-tongquan',
  templateUrl: './web-tongquan.component.html',
  styleUrls: ['./web-tongquan.component.scss'],
})
export class WebTongquanComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private dialog: MatDialog,
    private qldtApiService: QldtApiService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
  ) { }

  idloaibaiviet: any;
  idbaiviet: any;
  HOST = environment.PATH_IMAGE;
  basePath = environment.APP_HOME_PATH;
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: 1,
    columnName: null,
    childType: null,
    language: "VN",
  });
  target: Noidungbaiviet;
  listtinkhac: Noidungbaiviet[] = [];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.idloaibaiviet = params["idloaibaiviet"];
      this.idbaiviet = params["idbaiviet"];

      if (this.idbaiviet == 0) {
        this.qldtApiService.appWebHomeApiV1WebHomeGettingioithieu(this.idloaibaiviet).subscribe(res => {
          if (res.code == SUCCESS_RESPONE) {
            this.target = res.data;
          }
        })
      } else {
        this.qldtApiService.appWebHomeApiV1WebHomeGetbaiviet(this.idbaiviet).subscribe(res => {
          if (res.code == SUCCESS_RESPONE) {
            this.target = res.data;
          }
        })
      }

      this.qldtApiService.appWebHomeApiV1WebHomeGettingioithieukhac(this.idloaibaiviet).subscribe(res => {
        if (res.code == SUCCESS_RESPONE) {
          this.listtinkhac = res.data.filter(x => x.id != this.idbaiviet);
        }
      })
    });
    this.loadData();
  }

  loadData() {
    // this.service.pagingCustom(this.searchModel).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.target = res.data;
    //   } else {
    //     this.commonService.toastrDanger(res.message);
    //   }
    // });
  }

  toggleMenu(event: Event) {
    const element = event.currentTarget as HTMLElement;
    element.classList.toggle('open');
  }

  tinTucGioiThieu(idbaiviet: any) {
    this.router.navigate(["web-home/tintucgioithieu/" + this.idloaibaiviet + '/' + idbaiviet]);
  }
  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (
      document.body.scrollTop > 40 ||
      document.documentElement.scrollTop > 40
    ) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction1() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

}
