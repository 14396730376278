import { FormControl, FormGroup, NgForm } from "@angular/forms";
import {
  
  AttFileattachmentEntity,
  FileParameter,
  PageModel,
  
  // DmLienheEntity,
  // LienheModel,
  
} from "./../../../@core/data/app-home.service";
import { Component, HostListener, OnInit } from "@angular/core";
import { LienheService } from "./web-lienhe.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { CommonService } from "../../../@core/customs/common.service";
// import {
//   FileParameter,
//   UnitSelectModel,
// } from "../../../@core/data/app-qtht.service";
import {
  PAGE_SIZE_CUSTOM,
  SUCCESS_RESPONE,
  TYPE_MENU,
  TYPES_FILE_ACCEPT,
} from "../../../@core/customs/constants";

import {
  ToolbarService,
  LinkService,
  ImageService,
  HtmlEditorService,
} from "@syncfusion/ej2-angular-richtexteditor";

@Component({
  selector: "ngx-web-lienhe",
  templateUrl: "./web-lienhe.component.html",
  styleUrls: ["./web-lienhe.component.scss"],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService],
})
export class WebLienheComponent implements OnInit {
  public tools: object = {
    type: "MultiRow",
    items: [
      "Bold",
      "Italic",
      "Underline",

      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "SourceCode",
      "|",
      "Undo",
      "Redo",
    ],
  };

  public insertImageSettings: Object = {
    saveUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Save",
    removeUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Remove",
  };
  token: string | undefined;
  idloaibaiviet: any = "0";
  id: any = 0;
  basePath = environment.APP_HOME_PATH;
  idluongsukien: string;
  constructor(
    private service: LienheService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.token = undefined;
    this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.idloaibaiviet = params["idloaibaiviet"];
    });
  }

  ngOnInit(): void {
    // this.getUnit();
    this.loadData_luongsukien();
  }
  deo: FormGroup = new FormGroup({
    noidung: new FormControl(null),
  });
  currentDate = new Date();
  LstbaivietCT: any = {
    data: [],
  };
  // lstUnit: UnitSelectModel[];
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE_CUSTOM,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: "VN",
  });
  chitietbaitin: any = {
    data: [],
  };

  listTypeFileAccept: any[] = TYPES_FILE_ACCEPT;
  selectedFiles: FileParameter[] = [];
  countSizeFiles: number = 0;
  chooseFile(files: any[]) {
    if (files.length === 0) {
      return;
    }
    this.countSizeFiles = 0;
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (
        files[i].size > 20000000 ||
        !this.listTypeFileAccept.includes(files[i].type)
      ) {
        this.commonService.toastrWarning("File đính kèm không hợp lệ");
        return;
      }
      this.countSizeFiles += files[i].size;
      const fileParameter: FileParameter = {
        data: files[i],
        fileName: files[i]["name"],
      };
      this.selectedFiles.push(fileParameter);
    }
    if (this.countSizeFiles > 20000000) {
      this.commonService.toastrWarning("File đính kèm quá dung lượng");
      this.selectedFiles = [];
      return;
    }
  }
  checkcaptcha: boolean = true;
  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.checkcaptcha = false;
    }
  }
  deleteFile(file: any) {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) this.selectedFiles.splice(index, 1);
  }

  // target: LienheModel = new LienheModel();
    target: any;

  create() {
    if (confirm("Bạn có muốn gửi góp ý ?")) {
      const listFile = this.selectedFiles;
      if (listFile.length > 0) {
        // this.commonService.uploadFile("Lien_he", listFile).subscribe((res) => {
          // if (res.code === SUCCESS_RESPONE) {
            // this.target.lstFileAttachment = res.data.map((file) => {
            //   return new AttFileattachmentEntity({
            //     fileid: file.id,
            //     filename: file.fileName,
            //     type: TYPE_MENU.LIENHE,
            //   });
            // });
            // this.save();
          }
        // });
      // } else {
        this.save();
      // }
    }
  }
  clearForm(){
    this.target.tieuDe = '';
    this.target.tenNguoiGui = '';
    this.target.email = '';
    this.target.dienThoai = '';
    this.target.noiDung = '';
    this.selectedFiles = [];
    this.deo.reset();
  }

  save() {
    // this.target.noiDung = this.deo.controls["noidung"].value;
    // this.target.timePhanHoi = null;
    // this.target.time = this.currentDate;
    // this.target.trangThai = 0;
    // this.target.unitCode = null;
    // this.target.phanHoi = null;
    // this.target.matrangthai = "1";
    // this.target.loaiGopY = "lien-he"
    // this.service.CreateLienHe(this.target).subscribe((res) => {
    //   if(this.target.tenNguoiGui == "" || this.target.tenNguoiGui == null || this.target.tenNguoiGui.length == 0){
    //     alert("Vui lòng nhập họ tên !");
    //   }
    //   else if(this.target.tieuDe == "" || this.target.tieuDe == null || this.target.tieuDe.length == 0){
    //     alert("Vui lòng nhập tiêu đề !");
    //   }
    //   else if(this.target.noiDung == "" || this.target.noiDung == null || this.target.noiDung.length == 0){
    //     alert("Vui lòng nhập nội dung !");
    //   }
    //   else{
    //     if (res.code === SUCCESS_RESPONE) {
    //       alert("Bạn đã gửi góp ý thành công, xin cảm ơn!");
    //       window.location.reload();
    //     }
    //   }
    // });
  }

  // getTenUnit(value: string) {
    // if (value === null) return null;
    // const name = this.lstUnit.find((x) => x.unitcode === value)?.unitname;
    // return name;
  // }

  // getUnit() {
  //   this.service.Getsysunitlist().subscribe((result) => {
  //     if (result.code === SUCCESS_RESPONE) {
  //       this.lstUnit = result.data;
  //     } else {
  //       this.commonService.toastrDanger(result.message);
  //     }
  //   });
  // }

  // loadData_by_id() {
    // this.service.getbyid(this.id).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.chitietbaitin = res.data;
    //   }
    // });
  // // }
  // clickloadtintuc(id: number, loaibaiviet: string) {
  //   this.router
  //     .navigate(["web-home/chitietphanhoi/" + id])
  //     .then(() => {

  //        this.id = id;
  //       this.loadData_by_id();
  //     });
  // }
 
  loadData_luongsukien() {
    this.searchModel.childType = this.idluongsukien;
    // this.service.pagingCustom(this.searchModel).subscribe((res) => {
    //   if (res.code === SUCCESS_RESPONE) {
    //     this.Lstluongsk = res.data.data.slice(0, 10);
    //   }
    // });
  }
  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (
      document.body.scrollTop > 40 ||
      document.documentElement.scrollTop > 40
    ) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction1() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  toggleMenu(event: Event) {
    const element = event.currentTarget as HTMLElement;
    element.classList.toggle('open');
  }

}
