import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../@core/customs/constants';
import { PageModel, QldtApiService } from '../../../@core/data/app-home.service';

@Component({
  selector: 'ngx-web-timkiem',
  templateUrl: './web-timkiem.component.html',
  styleUrls: ['./web-timkiem.component.scss'],
})
export class WebTimkiemComponent implements OnInit {
  constructor(
    private qldtApiService: QldtApiService,
    private commonService: CommonService,
    private _router: Router,
    private dialogRef: MatDialogRef<WebTimkiemComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { noidung: string }
  ) {}

  target: any = {
    data: [],
  };
  searchItem: any = {};
  ngOnInit(): void {
    this.searchItem = this.searchModel.clone();
    this.searchModel.search = this.data.noidung;
    this.searchModel.startDate = this.searchModel.startDate ? new Date(this.searchModel.startDate) : null;
    this.searchModel.endDate = this.searchModel.endDate ? new Date(this.searchModel.endDate) : null;
    let a:string = this._router.url;
    // if (a.includes('/web-englis')) {
    //     this.searchModel.ngonNgu = 'EN';
    // } else {
    //     this.searchModel.ngonNgu = 'VN';
    // }
    this.loadData();
  }
   HOST = environment.PATH_IMAGE;
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: 10,
    tag: null,
    startDate: null,
    endDate: null,
  });
  async geturl_image(thumb) {
    const url = this.HOST + thumb;
    return url;
  }
  detailData(id: number, loaibv: string, luongsk: string) {
    const currentUrl = '/web-home/chitiettintuc/' + id + '/'+ loaibv + '/' + '0' ;
    this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([currentUrl]);
    });
    this.dialogRef.close(true);
  }

  tuNgay: any = null;
  denNgay: any = null;

  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  loadData() {
    this.searchModel.startDate = this.tuNgay ? new Date(this.tuNgay) : null;
    this.searchModel.endDate = this.denNgay ? new Date(this.denNgay) : null;
    this.qldtApiService.appWebHomeApiV1WebHomePagingCustomXemThem(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
}
