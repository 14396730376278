import { DatePipe } from '@angular/common';
import { Component, Injectable, Pipe, PipeTransform } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { HEIGHT_DIALOG, TITLE_NOTICE, WIDTH_DIALOG, SUCCESS_NOTICE, SUCCESS_RESPONE } from './constants';
import { Observable } from 'rxjs';
// import { AppQthtApiService, FileParameter, FileResponse, ResponseModelListApiResponse } from '../data/app-qtht.service';
import { Worksheet } from 'exceljs';
import { QldtApiService } from '../data/app-home.service';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private toastrService: NbToastrService,
    // private appQthtApiService: AppQthtApiService,
    private spinner: NgxSpinnerService,
    private appHomeService: QldtApiService
  ) { }


  // uploadFile(folder: string, files: FileParameter[]): Observable<ResponseModelListApiResponse> {
  //   // return this.appHomeService.appWebHomeApiWebHomeUploadFileFolder(folder, files);
  //   return this.appQthtApiService.appQthtApiFileUploadfile(folder, files);
  // }

  // uploadFileHome(folder: string, files: FileParameter[]): Observable<ResponseModelListApiResponse> {
    // return this.appHomeService.appWebHomeApiWebHomeUploadFileFolder(folder, files);
    // return this.appHomeService.appHomeApiFileUploadFile(folder, files);
  // }

  // downloadFile(fileId: number): void {
  //   this.appQthtApiService.appQthtApiFileDownloadfile(fileId).subscribe(response => {
  //     const blob = new Blob([response.data], { type: response.headers['content-type'] });
  //     const a = document.createElement('a');
  //     a.setAttribute('style', 'display:none;');
  //     document.body.appendChild(a);
  //     a.download = b64DecodeUnicode(response.headers['x-file-name']);
  //     a.href = URL.createObjectURL(blob);
  //     a.target = '_blank';
  //     a.click();
  //     document.body.removeChild(a);
  //     if (response.status !== 200) {
  //       this.toastrDanger('Lỗi khi thực hiện tác vụ download.');
  //     }
  // //   });

  //   function b64DecodeUnicode(str) {
  //     // Going backwards: from bytestream, to percent-encoding, to original string.
  //     return decodeURIComponent(atob(str).split('').map(function(c) {
  //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //     }).join(''));
  //   }
  // }

  // getFile(fileId: number): Observable<FileResponse> {
  //   return this.appQthtApiService.appQthtApiFileDownloadfile(fileId);
  // }

  toastrSuccess(message?: string, title?: string) {
    this.toastrService.success(message ?? SUCCESS_NOTICE, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrPrimary(message: string, title?: string) {
    this.toastrService.primary(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrWarning(message: string, title?: string) {
    this.toastrService.warning(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrDanger(message: string, title?: string) {
    this.toastrService.danger(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrInfo(message: string, title?: string) {
    this.toastrService.info(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrShow(message: string, title?: string) {
    this.toastrService.show(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrDefault(message: string, title?: string) {
    this.toastrService.default(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  configDialog(
    widthDialog: string,
    data: any,
  ): MatDialogConfig {
    const DIALOG_CONFIG = new MatDialogConfig();
    // DIALOG_CONFIG.disableClose = true;
    DIALOG_CONFIG.width = widthDialog;
    DIALOG_CONFIG.height = 'auto';
    DIALOG_CONFIG.maxWidth = WIDTH_DIALOG;
    DIALOG_CONFIG.maxHeight = HEIGHT_DIALOG;
    DIALOG_CONFIG.data = data;
    return DIALOG_CONFIG;
  }

  /**
* thiết lập style khi export excel
* @param worksheet worksheet
* @param colStart cột đầu tiên
* @param colEnd  cột cuối cùng
* @param rowHeaderStart dòng tiêu đề bắt đầu
* @param rowHeaderEnd dòng tiêu đề kết thúc
* @param heightRowHeader chiều cao dòng header
* @param colCenter mảng cột căn giữa
* @param colRight mảng cột căn phải
* @param colBold mảng cột in đậm
*/
setStyleExport(worksheet: Worksheet, colStart: number, colEnd: number, rowHeaderStart: number,
  rowHeaderEnd: number, heightRowHeader?: number, colCenter?: number[], colRight?: number[], colBold?: number[]) {
  worksheet.mergeCells(1, colStart, 1, colEnd);
  worksheet.getCell(1, 1).style = {
    font: { bold: true, size: 14, name: 'Times New Roman' }, alignment: { vertical: 'middle', horizontal: 'center' }
  };
  worksheet.getRow(1).height = 40;
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber >= rowHeaderStart && rowNumber <= rowHeaderEnd) {
      worksheet.getRow(rowNumber).height = heightRowHeader ?? 25;
    }
    for (let colNumber = colStart; colNumber <= colEnd; colNumber++) {
      if (rowNumber >= rowHeaderStart && rowNumber <= rowHeaderEnd) {
        worksheet.getCell(rowNumber, colNumber).style = {
          border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
          font: { size: 10, name: 'Times New Roman', bold: true },
          alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' }
        };
      } else if (rowNumber > rowHeaderEnd) {
        if (colCenter?.includes(colNumber)) {
          worksheet.getCell(rowNumber, colNumber).style = {
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
            font: {
              size: 10, name: 'Times New Roman',
              bold: colBold && colBold.length > 0 ? colBold[colNumber - 1] === colNumber : false
            },
            alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' }
          };
        } else if (colRight?.includes(colNumber)) {
          worksheet.getCell(rowNumber, colNumber).style = {
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
            font: {
              size: 10, name: 'Times New Roman',
              bold: colBold && colBold.length > 0 ? colBold[colNumber - 1] === colNumber : false
            },
            alignment: { wrapText: true, vertical: 'middle', horizontal: 'right' }
          };
          worksheet.getCell(rowNumber, colNumber).numFmt = '#,##0';
        } else {
          worksheet.getCell(rowNumber, colNumber).style = {
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
            font: {
              size: 10, name: 'Times New Roman',
              bold: colBold && colBold.length > 0 ? colBold[colNumber - 1] === colNumber : false
            },
            alignment: { wrapText: true, vertical: 'middle', horizontal: 'left' }
          };
        }
      }
    }
  });
}
}



@Component({
  template: ''
})
export class VitualScrollNgSelect {
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;
  vitualData = [];
  lstVitualScroll = [];
  bufferSize = 10;

  constructor() { }

  setDataForVitualScroll(lst: any[]) {
    this.vitualData = lst;
    this.lstVitualScroll = this.vitualData.slice(0, this.bufferSize);
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.loading || this.vitualData.length <= this.lstVitualScroll.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.lstVitualScroll.length) {
      this.fetchMore();
    }
  }
  private fetchMore() {
    const len = this.lstVitualScroll.length;
    const more = this.vitualData.slice(len, this.bufferSize + len);
    this.loading = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loading = false;
      this.lstVitualScroll = this.lstVitualScroll.concat(more);
    }, 200);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor() { }
  filter(array, string) {
    function convertDate(params: string, str: string) {
      if ((params !== null || params !== undefined) && params.length === 8) {
        try {
          const year = params.substr(0, 4);
          const month = params.substr(4, 2);
          const day = params.substr(6, 2);
          if (`${day}/${month}/${year}`.includes(str)) {
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    }
    function checkData(params: string, str: string) {
      return params.toLowerCase().includes(str.toLowerCase())
        || convertDate(params, str) ? true : false;
    }
    if ((array !== null || array !== undefined) && (string !== null || string !== undefined) && string !== '') {
      return array.filter(o =>
        Object.keys(o).some(
          k => typeof (o[k]) === 'string'
            ? checkData(o[k], string)
            : typeof (o[k]) === 'number'
              ? o[k].toString().includes(string.toLowerCase())
              : false
        ));
    }
    return array;
  }

  searchItem(string: string, obj: any) {
    function convertDate(params: string, str: string) {
      if ((params !== null || params !== undefined) && params.length === 8) {
        try {
          const year = params.substr(0, 4);
          const month = params.substr(4, 2);
          const day = params.substr(6, 2);
          if (`${day}/${month}/${year}`.includes(str)) {
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    }

    function checkData(params: string, str: string) {
      return params.toLowerCase().includes(str.toLowerCase())
        || convertDate(params, str) ? true : false;
    }

    return Object.keys(obj).some(
      k => typeof (obj[k]) === 'string'
        ? checkData(obj[k], string)
        : typeof (obj[k]) === 'number'
          ? obj[k].toString().includes(string.toLowerCase())
          : false
    );
  }
}
@Pipe({
  name: 'custom_text'
})
export class CustomTextPipe implements PipeTransform {

  transform(value: string, limit: number = 20, completeWords: boolean = false, ellipsis: string = '...'): string {
    if (!value) return '';
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }

}

@Pipe({
  name: 'pipeDate',
})
export class ConvertDatePipe implements PipeTransform {
  //#region CONVERT_DATE

  transform(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;
  }

  transformDateTime(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'yyyy-MM-dd HH:mm');
    return value;
  }

  formatDateVN(value: any){
    const datePipe = new DatePipe('vi-VN');
    value = datePipe.transform(value,'dd-MM-yyyy');
    return value;
  }

  convertDateToStringExport(target: Date): string {
    if (target) {
      return target.getDate() + '/' + (target.getMonth() + 1) + '/' + target.getFullYear();
    }
    return null;
  }

  convertDateTimeToStringExport(target: Date): string {
    if (target) {
      return target.getDate() + '/' + (target.getMonth() + 1) + '/' + target.getFullYear() + ' ' + target.getHours() + ':' + target.getMinutes()+ ':' + target.getSeconds();
    }
    return null;
  }

  convertDateToString(target: Date): string {
    if (target) {
      return this.transform(target.toString());
    }
    return null;
  }
  convertStringToDateString(target: string): string {
    if (target) {
      if (target.length === 8) {
        const year = target.substr(0, 4);
        const month = target.substr(4, 2);
        const day = target.substr(6, 2);
        return day + '/' + month + '/' + year;
      }
      if (target.length === 10) {
        const year = target.substr(0, 4);
        const month = target.substr(5, 2);
        const day = target.substr(8, 2);
        return day + '/' + month + '/' + year;
      }
      return target;
    }
  }
  convertStringToDate(target: string): Date {
    if (target && target.length === 8) {
      const year = +target.substr(0, 4);
      const month = +target.substr(4, 2);
      const day = +target.substr(6, 2);
      return new Date(year, month, day);
    }
    return null;
  }

  convertStringVnToDate(target: string): Date {
    if (target && target.length === 10) {
      const year = + target.substr(6, 4);
      const month = + target.substr(3, 2);
      const day = + target.substr(0, 2);
      return new Date(year, month, day);
    }
    return null;
  }

  convertStringToFormatInput(target: string): string {
    if (target && target.length === 8) {
      const year = target.substr(0, 4);
      const month = target.substr(4, 2);
      const day = target.substr(6, 2);
      return year + '-' + month + '-' + day;
    }
    return target;
  }

  convertStringddMMyyyyToFormatTextVN(target: string) {
    if (target && target.length === 8) {
      const year = target.substr(0, 4);
      const month = target.substr(4, 2);
      const day = target.substr(6, 2);
      return 'Ngày ' + day + ' tháng ' + month + ' năm ' + year;
    }
    return target;
  }

  convertStringDisplayToDateString(target: string) {
    if (target && target.length === 10) {
      const year = target.substr(0, 4);
      const month = target.substr(5, 2);
      const day = target.substr(8, 2);
      return year + month + day;
    }
    return target;
  }
  //#endregion
}
