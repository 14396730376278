<div class="container-fluid">
  <div class="container">
    <img src="{{ basePath }}/assets/images/header.png" width="100%" alt="">
    <div class="row" style="padding: 0 7px">
      <div class="col-2 text-center" style="background-color: #09C8D6;">
        <p class="text-light fw-bold py-2 mb-0">{{ today | date: 'EEEE' }}, {{ today | date: 'dd/MM/yyyy' }}</p>
      </div>
      <div class="col-7">
        <marquee behavior="scroll" direction="left" class="mt-2">
          <span class="py-2 mb-0 me-5">1. Lãnh đạo Bộ Tài chính chúc mừng thầy, cô giáo Trường Bồi dưỡng cán bộ
            tài chính</span>
          <span class="py-2 mb-0 me-5">2. Lãnh đạo Bộ Tài chính chúc mừng thầy, cô giáo Trường Bồi dưỡng cán bộ
            tài chính</span>
          <span class="py-2 mb-0 me-5">3. Lãnh đạo Bộ Tài chính chúc mừng thầy, cô giáo Trường Bồi dưỡng cán bộ
            tài chính</span>
        </marquee>
      </div>
      <div class="col-3 pe-0">
        <div class="d-flex justify-content-end">
          <input type="text" placeholder="Nhập từ khoá tìm kiếm" style="height: 28px; width: 95%; margin-top: 4px;"
            [(ngModel)]="searchModel.searchByNoiDung" (keyup.enter)="search()"/>
          <button type="submit" class="btn-search">
            <i class="fas fa-search" (click)="search()" style="font-size: 13px;"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<nav id="bs1" class="navbar-expand-md sticky-top">
  <div class="container-fluid">
    <div class="container">
      <div class="row" style="justify-content: flex-start !important;">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <div class="collapse navbar-collapse d-flex" id="mynavbar" style="justify-content: flex-start !important;">
            <ul class="navbar-nav d-flex flex-row" style="justify-content: flex-start !important;">
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" routerLink="/web-home" routerLinkActive="active" style="margin-left: unset !important;">
                  <div>Trang chủ</div>
                </a>
              </li>
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" >
                  <div>Giới thiệu <span class="scaret"></span></div>
                  <ul class="sub-menu">
                    <li><a href="javascript:void(0)" (click)="tinTucGioiThieu('021')">Cơ cấu tổ chức</a></li>
                    <li><a href="javascript:void(0)" (click)="tinTucGioiThieu('022')">Giới thiệu chung</a></li>
                    <li><a href="javascript:void(0)" (click)="tinTucGioiThieu('023')">Ban giám đốc</a></li>
                    <li><a href="javascript:void(0)" (click)="tinTucGioiThieu('024')">Giảng viên tiêu biểu</a></li>
                  </ul>
                </a>
              </li>
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" routerLink="/web-home/xemthemtintuc/01">
                  <div>Tin tức sự kiện</div>
                </a>
              </li>
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" routerLink="/web-home">
                  <div>Hoạt động đào tạo<span class="scaret"></span></div>
                </a>
                <ul class="sub-menu">
                  <li><a (click)="clickXemTin('031')"> Các chương trình đào tạo</a></li>
                  <li><a (click)="clickXemTin('032')"> Kế hoạch đào tạo bồi dưỡng</a></li>
                  <li><a (click)="clickXemTin('033')"> Tổ chức đào tạo bồi dưỡng</a></li>
                  <li><a (click)="clickXemTin('034')"> Hoạt động đào tạo bồi dưỡng</a></li>
                  <li><a routerLink="/web-home/tracuudiem"> Tra cứu điểm thi</a></li>
                  <li><a > Chuyển đổi dữ liệu</a></li>
                </ul>
              </li>
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" routerLink="/web-home">
                  <div>Hợp tác quốc tế<span class="scaret"></span></div>
                </a>
                <ul class="sub-menu">
                  <li><a (click)="clickXemTin('051')"> Chương trình đào tạo nước ngoài</a></li>
                  <li><a (click)="clickXemTin('052')"> Chương trình đào tạo trong nước</a></li>
                  <li><a (click)="clickXemTin('053')"> Hội thảo quốc tế</a></li>
                </ul>
              </li>
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" routerLink="/web-home">
                  <div>Nghiên cứu khoa học<span class="scaret"></span></div>
                </a>
                <ul class="sub-menu">
                  <li><a (click)="clickXemTin('041')"> Hội thảo khoa học</a></li>
                  <li><a (click)="clickXemTin('042')"> Các đề tài nghiên cứu khoc học cấp Bộ</a></li>
                  <li><a (click)="clickXemTin('043')"> Các chương trình nghiên cứu của trường</a></li>
                  <li><a (click)="clickXemTin('044')"> Chương trình nghiên cứu cấp nhà nước</a></li>
                </ul>
              </li>
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" routerLink="/web-home">
                  <div>Tài nguyên</div>
                </a>
              </li>
              <li class="nav-item nv mb-1">
                <a class="nav-link nv center" routerLink="/web-home/lienhe">
                  <div>Liên hệ</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>