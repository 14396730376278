<nb-card>
    <nb-card-header class="nb-card-header">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                <h2 style="text-align: center; color: royalblue">Kết quả tìm kiếm</h2>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row hd1">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 row">
                <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2"></div>
                <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4">
                    <label>Từ khóa tìm kiếm</label>
                    <input autocomplete="off" type="text" name="search" class="form-control"  placeholder="Tìm kiếm" [(ngModel)]="searchModel.search" />
                </div>
                <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2">
                    <label>Từ ngày</label>
                    <input type="date" name="tungay" class="form-control"  [(ngModel)]="tuNgay" />
                </div>
                <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2">
                    <label>Đến ngày</label>
                    <input type="date" name="denngay" class="form-control"  [(ngModel)]="denNgay" />
                </div>
                <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2">
                    <br/>
                    <button type="button" class="btn btn-primary" (click)="search()"><i class="fa fa-search"></i> Tìm kiếm</button>
                </div>
            </div>
            <div class="row" style="margin-top: 20px; margin-bottom: 20px">

                <div class="col-md-12 post-item" *ngFor="let item of target.data" style="margin-top: 20px">
                    <a (click)="detailData(item.id, item.loaibaiviet, item.luongsukien)" style="font-size: 100%; cursor:pointer">
                        <img *ngIf="item.thumb" class="rounded thump" src="{{ HOST + item.thumb }} " />
                        <h5>{{ item.tieude }}</h5>
                        <time style="color: gray; font-style: italic">{{item.ngayxuatban | date: "dd/MM/yyyy "}}</time>
                        <br /> {{ item.noidungtomtat }}
                    </a>
                </div>
            </div>
            <div style="justify-content:center; display: flex;" *ngIf="target.data.length > 0">
                <ngx-pagination (pageChange)="searchModel.currentPage = $event; loadData()" (pageSize)="searchModel.pageSize = $event; loadData()" [currentPage]="searchModel.currentPage" [totalPages]="target.totalPage">
                </ngx-pagination>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer class="text-end">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
                <ngx-btn-close mat-dialog-close></ngx-btn-close>
            </div>
        </div>
    </nb-card-footer>
</nb-card>
